
























































































































































































import Vue from 'vue'
import '@/assets/csj-v1.0/style.css'
import VueApexCharts from 'vue-apexcharts'
import ApexCharts from 'apexcharts';
import { AppUtils } from '../../utils/AppUtils'
import { StringUtils } from '../../utils/StringUtils'
import { Device } from '../../dataTypes'

export default Vue.extend({
  name: 'Dashboard',
  data: () => ({
    fetchId: 0,
    fetchDeviceId: 0,
    lastDataFetchTime: 0,
    timerId: 0,
    loading: false,
    options: {},
    devices: [],
    totalDevices: 0,
    summaryLoading: false,

    setting: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'location',
      sortDesc: false,
    },

    printerStatusesSeries: [],
    printerStatusesChartOptions: {
      chart: {
        width: 330,
        type: 'donut',
        animations: {
          enabled: false
        }
      },
      labels: [],
      colors: [ '#2E93fA', '#E91E63', 'silver' ],
      plotOptions: {
        pie: {
          expandOnClick: false,
          dataLabels: {
            offset: -2
          },
          donut: {
            size: '50%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: ""
              },
            }
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatter: function (val: string, opt) {
          if ((val+"").indexOf(".") != -1) {
            val = parseFloat(val).toFixed(2);
          }
          return [opt.w.globals.labels[opt.seriesIndex], val + '%'];
        },
        style: {
            fontSize: '14px',
        },
      },
      tooltip: {
         fillSeriesColor: false,
         theme: 'light',
      }
    },
    errorStatusesSeries: [ 0, 0 ],
    errorStatusesChartOptions: {
      chart: {
        width: 330,
        type: 'donut',
        animations: {
          enabled: false
        }
      },
      labels: [],
      theme: {
        monochrome: {
          enabled: true,
          color: '#E91E63',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          dataLabels: {
            offset: -2
          },
          donut: {
            size: '50%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: ""
              },
            }
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatter: function (val: string, opt) {
          if ((val+"").indexOf(".") != -1) {
            val = parseFloat(val).toFixed(2);
          }
          return [opt.w.globals.labels[opt.seriesIndex], val + '%'];
        },
        style: {
            fontSize: '14px',
        },
      },
      tooltip: {
         fillSeriesColor: false,
         theme: 'light',
      }
    },
    printerModelsSeries: [{
      name: "",
      data: []
    }],
    printerModelsChartOptions: {
      chart: {
        toolbar: {
          show: false
        },

        height: 350,
        type: 'bar',
        animations: {
          enabled: false
        }
      },
      colors: [
        "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0",
        "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800",
        "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B",
        "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A",
        "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7",
        "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D",
        "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
        "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD",
        "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF",
        "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2"
      ],
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
      },
      xaxis: {
        type: 'category',
        categories: [
        ],
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        max: 0
      },
    },
  }),
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    headers: function (): { text: string, value: string, width?: string, filter?: (arg: string | number) => boolean, align?: string, sortable?: boolean, cellClass?: string }[] {
      return [
        { text: this.$t("dashboard.column_place") as string, width: "180", value: 'location', cellClass:'cell-location' },
        { text: this.$t("dashboard.column_serialno") as string, width: "140", value: 'serialNumber' },
        { text: this.$t("dashboard.column_deviceid") as string, width: "100", value: 'deviceId' },
        { text: this.$t("dashboard.column_modelname") as string, width: "110", value: 'modelName' },
        { text: this.$t("dashboard.column_fwver") as string, width: "200", value: 'firmwareId' },
        { text: this.$t("dashboard.column_devstatus") as string, width: "120", value: 'stateCode', align: 'center' },
      ];
    },
  },
  watch: {
    options: {
      handler () {
        let i = 0;
        let selIndex = -1;
        for (let obj of [this.$refs.printerStatusChart, this.$refs.errorStatusChart, this.$refs.printerModelChart]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const selected: number[] = obj.chart.w.globals.selectedDataPoints[0];
          if (selected && selected.length) {
            selIndex = selected[0];
            break;
          }
          i++;
        }

        if (selIndex != -1) {
          if (i == 0) {
            this.fetchDevices(0, { deviceStatus: selIndex + 1 });
          } else if (i == 1) {
            this.fetchDevices(0, { errorType: selIndex + 1 });
          } else if (i == 2) {
            this.fetchDevices(0, { deviceModel: this.printerModelsChartOptions.xaxis.categories[selIndex] });
          }
        }
      },
      deep: true,
    },
    '$i18n.locale': {
      handler: function() {
        this.printerStatusesChartOptions = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          labels: [this.$t("dashboard.list_normal"), this.$t("dashboard.list_error"), this.$t("dashboard.list_unknown")],
          plotOptions: {
            pie: {
              donut: {
                  labels: {
                    total: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      label: this.$t("dashboard.label_printer_count")
                    }
                  }
              }
            }
          }
        };

        this.errorStatusesChartOptions = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          labels: [this.$t("dashboard.label_serious_error"), this.$t("dashboard.label_error")],
          plotOptions: {
            pie: {
              donut: {
                  labels: {
                    total: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      label: this.$t("dashboard.label_error_count")
                    }
                  }
              }
            }
          }
        }

        // this.printerModelsSeries[0].name = this.$t("dashboard.label_count") as string;
      }
    }
  },
  methods: {
    onPrinterStatusChart(selIndexs: number[]) {
      this.clearSelection([this.$refs.errorStatusChart, this.$refs.printerModelChart]);
      (this.options as { page: number }).page = 1;
      if (selIndexs.length) {
        this.fetchDevices(0, { deviceStatus: selIndexs[0] + 1 });
      } else {
        this.devices = [];
        this.totalDevices = 0;          
      }
    },
    onErrorStatusChart(selIndexs: number[]) {
      this.clearSelection([this.$refs.printerStatusChart, this.$refs.printerModelChart]);
      (this.options as { page: number }).page = 1;
      if (selIndexs.length) {
        this.fetchDevices(0, { errorType: selIndexs[0] + 1 });
      } else {
        this.devices = [];
        this.totalDevices = 0;          
      }
    },
    onPrinterModelChart(selIndexs: number[]) {
      this.clearSelection([this.$refs.printerStatusChart, this.$refs.errorStatusChart]);
      (this.options as { page: number }).page = 1;
      if (selIndexs && selIndexs.length) {
        this.fetchDevices(0, { deviceModel: this.printerModelsChartOptions.xaxis.categories[selIndexs[0]] });
      } else {
        this.devices = [];
        this.totalDevices = 0;          
      }
    },
    clearSelection(objs: unknown[]) {
      for (let obj of objs) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const selected: number[] = obj.chart.w.globals.selectedDataPoints[0];
        if (selected && selected.length) {
          if ((obj as { type: string}).type == 'bar') {
            (obj as ApexCharts).toggleDataPointSelection(0, selected[0]);
          } else {
            (obj as ApexCharts).toggleDataPointSelection(selected[0]);
          }
          (obj as ApexCharts).resetSeries();
        }
      }
    },
    itemRowBackground(device: Device) {
      return this.getSeriousErrorMessage(device) != "" ? 'style-err-summary': '';
    },
    getPagenationText(props: { pageStart: string, pageStop: string, itemsLength: string }): string {
      return StringUtils.format(this.$t("dashboard.table_pagenation") as string, props.pageStart, props.pageStop, props.itemsLength);
    },
    getStatusString(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      
      if (status == 3) {
        return this.$t("dashboard.list_unknown");
      } else if (status == 1) {
        return this.$t("dashboard.list_normal");
      }
      return this.$t("dashboard.list_error");
    },
    getErrorMessage(device: Device) {
      if (AppUtils.getStatus(device.stateCode, device.logDateTime) == 3) {
        return "";
      }
      return AppUtils.getErrorMessage(device);
    },
    getSeriousErrorMessage(device: Device) {
      if (AppUtils.getStatus(device.stateCode, device.logDateTime) == 3) {
        return "";
      }
      return AppUtils.getErrorMessage(device, 0x08);
    },
    getStateIcon(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      if (status == 3) {
        return 'icon-status-question_icon';
      } else if (status == 1) {
        return 'icon-status-ok_icon';
      }
      return 'icon-status-ng_icon';
    },
    getStateIconColor(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      if (status == 3) {
        return 'blue-grey darken-2';
      } else if (status == 1) {
        return 'blue darken-2';
      }
      return 'red darken-2';
    },
    fetchSummary(delay: number) {
      if (this.fetchId) {
        window.clearTimeout(this.fetchId);
        this.fetchId = 0;
      }

      this.fetchId = window.setTimeout(() => {

        this.$store.dispatch("user/fetchSummary").then((resp) => {
          
          // プリンタステータスの表示
          const printerStatuses = [ resp.statuses.normal, resp.statuses.error, resp.statuses.unknown ];
          const beforePrinterStatus = JSON.stringify((this.printerStatusesSeries as unknown as number[]));
          const currentPrinterStatus = JSON.stringify(printerStatuses);

          if (beforePrinterStatus != currentPrinterStatus) {
            (this.printerStatusesSeries as unknown as number[]) = printerStatuses;
          }

          // エラーステータスの表示
          const errorStatuses = [ resp.errors.fatal, resp.errors.minor ];
          const beforeErrorStatus = JSON.stringify((this.errorStatusesSeries as unknown as number[]));
          const currentErrorStatus = JSON.stringify(errorStatuses);
          if (beforeErrorStatus != currentErrorStatus) {
            this.errorStatusesSeries = errorStatuses;
          }

          // モデルの表示
          let series: number[] = [];
          let categories: string[] = [];
          let models: { [key: string]: number } = resp.models as { [key: string]: number };
          let maxValue = 5;
          const keys = Object.keys(models).sort();
          for (let model of keys) {
            series.push(models[model]);
            categories.push(model);
            maxValue = Math.max(maxValue, models[model]);
          }

          const beforePrinterModelCount = JSON.stringify((this.printerModelsSeries[0].data as unknown as number[]).slice().sort());
          const currentPrinterModelCount = JSON.stringify(series.slice().sort());
          const beforePrinterModels = this.printerModelsChartOptions.xaxis ? JSON.stringify((this.printerModelsChartOptions.xaxis.categories as unknown as string[]).slice().sort()): "";
          const currentPrinterModels = JSON.stringify(categories.slice().sort());
          if (beforePrinterModelCount != currentPrinterModelCount || beforePrinterModels != currentPrinterModels) {
            (this.printerModelsSeries[0].data as unknown as number[]) = series;
            (this.printerModelsChartOptions as { xaxis: { categories: string[] }, yaxis: { max: number } }) = {
              xaxis: {
                categories: categories,
              },
              yaxis: {
                max: maxValue
              }
            };
          }
        }).catch((err) => {
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchId = 0;
        this.lastDataFetchTime = (new Date).getTime();
      }, delay);
    },
    fetchDevices(delay: number, filter?: { deviceStatus?: number, errorType?: number, deviceModel?: string }) {
      if (this.fetchDeviceId) {
        window.clearTimeout(this.fetchDeviceId);
        this.fetchDeviceId = 0;
      }

      this.fetchDeviceId = window.setTimeout(() => {
        let param = {
          options: this.options
        };

        if (filter && 'deviceStatus' in filter) {
          param = Object.assign(param, { deviceStatusFilterValue: filter.deviceStatus });
        }
        if (filter && 'errorType' in filter) {
          param = Object.assign(param, { errorType: filter.errorType });
        }
        if (filter && 'deviceModel' in filter) {
          param = Object.assign(param, { deviceModel: filter.deviceModel });
        }

        this.loading = true;
        this.$store.dispatch("user/fetchDevices", param).then((resp) => {
          this.devices = resp.devices;
          this.totalDevices = resp.count;
          this.loading = false;
        }).catch((err) => {
            this.loading = false;
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchDeviceId = 0;
      }, delay);
    }
  },
  mounted() {
    this.printerStatusesChartOptions = {
      chart: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        events: {
          click: (event: unknown, chartContext: unknown, config: unknown) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.onPrinterStatusChart(config.globals.selectedDataPoints[0]);
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      labels: [this.$t("dashboard.list_normal"), this.$t("dashboard.list_error"), this.$t("dashboard.list_unknown")],
      plotOptions: {
        pie: {
          donut: {
              labels: {
                total: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  label: this.$t("dashboard.label_printer_count")
                }
              }
          }
        }
      }
    };

    this.errorStatusesChartOptions = {
      chart: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        events: {
          click: (event: unknown, chartContext: unknown, config: unknown) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.onErrorStatusChart(config.globals.selectedDataPoints[0]);
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      labels: [this.$t("dashboard.label_serious_error"), this.$t("dashboard.label_error")],
      plotOptions: {
        pie: {
          donut: {
              labels: {
                total: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  label: this.$t("dashboard.label_error_count")
                }
              }
          }
        }
      }
    }

    this.printerModelsChartOptions = {
      chart: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        events: {
          click: (event: unknown, chartContext: unknown, config: unknown) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.onPrinterModelChart(config.globals.selectedDataPoints[0]);
          }
        }
      }
    }

    // this.printerModelsSeries[0].name = this.$t("dashboard.label_count") as string;

    this.fetchSummary(0);
    this.timerId = setInterval(() => {
      if ((new Date).getTime() - this.lastDataFetchTime > 1000 * 10) {
        this.fetchSummary(0);
      }
    }, 1000) as unknown as number;
  },
  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = 0;
    }
  }
})
