var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"dashboard-column d-flex",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('div',{staticClass:"dashboard-card"},[_c('p',{staticClass:"text-subtitle-2 pt-1 pb-1 pr-1 pl-3 dashboard-card-title"},[_vm._v(_vm._s(_vm.$t("dashboard.label_printer_status")))]),_c('div',[_c('apexchart',{ref:"printerStatusChart",attrs:{"type":"donut","width":"100%","height":"340","options":_vm.printerStatusesChartOptions,"series":_vm.printerStatusesSeries}})],1)])]),_c('v-col',{staticClass:"dashboard-column d-flex",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('div',{staticClass:"dashboard-card"},[_c('p',{staticClass:"text-subtitle-2 pt-1 pb-1 pr-1 pl-3 dashboard-card-title"},[_vm._v(_vm._s(_vm.$t("dashboard.label_error_status")))]),_c('div',[_c('apexchart',{ref:"errorStatusChart",attrs:{"type":"donut","width":"100%","height":"340","options":_vm.errorStatusesChartOptions,"series":_vm.errorStatusesSeries}})],1)])]),_c('v-col',{staticClass:"dashboard-column d-flex",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('div',{staticClass:"dashboard-card"},[_c('p',{staticClass:"text-subtitle-2 pt-1 pb-1 pr-1 pl-3 dashboard-card-title"},[_vm._v(_vm._s(_vm.$t("dashboard.label_printer_model")))]),_c('div',[_c('apexchart',{ref:"printerModelChart",attrs:{"type":"bar","height":"320","options":_vm.printerModelsChartOptions,"series":_vm.printerModelsSeries}})],1)])])],1),_c('div',{staticClass:"printer-table mt-6"},[_c('v-data-table',{ref:"table",staticClass:"pa-4 printer-list-summary",attrs:{"dense":"","calculate-widths":"","headers":_vm.headers,"loading":_vm.loading,"items":_vm.devices,"options":_vm.options,"server-items-length":_vm.totalDevices,"page":_vm.setting.page,"items-per-page":_vm.setting.itemsPerPage,"sort-by":_vm.setting.sortBy,"sort-desc":_vm.setting.sortDesc,"footer-props":{
        itemsPerPageOptions: [ 10, 50, 100, 500, 1000 ],
        itemsPerPageText: _vm.$t('printer_management.table_rowperpage')
      },"item-class":_vm.itemRowBackground,"item-key":"deviceId"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.$set(_vm.setting, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.setting, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.setting, "itemsPerPage", $event)},"update:sortBy":function($event){return _vm.$set(_vm.setting, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.setting, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.setting, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.setting, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.getPagenationText(props))+" ")]}},{key:"item.stateCode",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.getStateIconColor(parseInt(item.stateCode), item.logDateTime)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":_vm.getStateIconColor(parseInt(item.stateCode), item.logDateTime)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getStateIcon(parseInt(item.stateCode), item.logDateTime))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStatusString(parseInt(item.stateCode), item.logDateTime)))]),_c('span',{class:_vm.getErrorMessage(item) ? 'pl-3': 'pl-0'},[_vm._v(" "+_vm._s(_vm.getErrorMessage(item))+" ")])])]}},{key:"item.detail",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"x-small-button",attrs:{"elevation":"2","color":"white","tile":""},on:{"click":function($event){return _vm.onPageDeviceMSW(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" icon-edit_icon ")])],1)]}},{key:"item.location",fn:function(ref){
      var item = ref.item;
return [(_vm.itemRowBackground(item) === 'style-err-summary')?_c('v-tooltip',{attrs:{"bottom":"","color":_vm.getStateIconColor(parseInt(item.stateCode), item.logDateTime)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation-thick ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getSeriousErrorMessage(item))+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.location)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }