import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../../views/user/Dashboard.vue'
import Devices from '../../views/user/Devices.vue'
import DeviceMSW from '../../views/user/DeviceMSWSetting.vue'
import Agents from '../../views/user/Agents.vue'
import AgentDetail from '../../views/user/AgentDetail.vue'
import JobQueue from '../../views/user/JobQueue.vue'
import JobHistory from '../../views/user/JobHistory.vue'
import SessionError from '../../views/common/SessionError.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/devices',
    name: 'Devices',
    component: Devices
  },
  {
    path: '/devices/:id',
    name: 'DeviceMSW',
    component: DeviceMSW
  },
  {
    path: '/agents',
    name: 'Agents',
    component: Agents
  },
  {
    path: '/agents/:id',
    name: 'AgentDetail',
    component: AgentDetail
  },
  {
    path: '/job-queue',
    name: 'JobQueue',
    component: JobQueue
  },
  {
    path: '/job-history',
    name: 'JobHistory',
    component: JobHistory
  },
  {
    path: '/session-error',
    name: 'SessionError',
    component: SessionError,
    meta: {
      hideAppBar: true,
      error: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
