













































































































































































  import Vue, { PropType } from 'vue'
  import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
  import MessageBox from '../../components/common/MessageBox.vue'
  import { FileInfo } from '../../dataTypes'
  import { AppUtils } from '../../utils/AppUtils'
import { StringUtils } from '@/utils/StringUtils'

  export default Vue.extend({
    name: 'PrinterSendFileDialog',

    data: () => ({
      valid: false,
      selected: [],
      showDeleteFileMessageBox: false,
      jobSettingDialogVisible: false,
      showErrorAlert: false,
      targetFile: {},
      currentMemo: ""
    }),
    watch: {
      isVisible: {
        handler: function(newVal, oldVal) {
          if (!oldVal && newVal && this.$refs.form) {
            (this.$refs.form as HTMLFormElement).reset();
            this.selected = [];
          }
        }
      }
    },    
    props: {
      isVisible: Boolean,
      files: Array as PropType<FileInfo[]>,
    },
    components: {
      JobSettingDialog,
      MessageBox,
    },
    computed: {
      headers() {
        return [
          { text: this.$t("file_send_dlg.column_filename"), value: 'name'  },
          { text: this.$t("file_send_dlg.column_date"), value: 'date'  },
          { text: '', value: 'delete', sortable: false, width: 20  },
        ]
      },
    },
    methods: {
      isSelected(item: FileInfo) {
        return (this.selected as FileInfo[]).find(e => e.fileId == item.fileId);
      },
      onChange(item: FileInfo) {
        if (this.isSelected(item)) {
           (this.selected as FileInfo[]) = (this.selected as FileInfo[]).filter(el => item.fileId !== el.fileId);
        } else {
          (this.selected as FileInfo[]) = [item];
        }
      },
      toLocalString(str: string) {
        return StringUtils.toLocalString(str);
      },
      showJobSettingDialog() {
        this.jobSettingDialogVisible = true;
      },
      closeJobSettingDialog(param: string) {
        if (param !== null) {
          const device = this.selected && this.selected.length ? this.selected[0]: null;
          this.$emit('close-request', { info: device, atOption: param });
        }
        this.jobSettingDialogVisible = false;
      },
      onMouseOverRow(item: FileInfo) {
        this.currentMemo = item.memo ? item.memo: "";
      },
      onMouseLeaveRow() {
        this.currentMemo = "";
      },
      onDelete(item: FileInfo) {
        this.targetFile = item;
        this.showDeleteFileMessageBox = true;
      },
      onDeleteFile() {
        this.$store.dispatch("user/unregisterFile", this.targetFile).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.showErrorAlert = true;
        });
        this.showDeleteFileMessageBox = false;
        this.targetFile = {};
      }
    }
  })
