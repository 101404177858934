



























































































































import Vue from 'vue'
import MessageBox from '../../components/common/MessageBox.vue'
import { StringUtils } from '../../utils/StringUtils'
import { FileInfo } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'

type DataType = {
  valid: boolean;
  uploading: boolean;
  showErrorAlert: boolean;
  fileInfo: FileInfo;
}

export default Vue.extend({
  name: 'FileRegisterDialog',

  data(): DataType {
    return {
      valid: false,
      uploading: false,
      showErrorAlert: false,
      fileInfo: { file: undefined, memo: '' },
    }
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal && this.$refs.form) {
          (this.$refs.form as HTMLFormElement).reset();
        }
      }
    }
  },
  computed: {
    fileRules() {
      return [
        (v: string) => !!v || this.$t("messages.register_file_validation"),
      ];
    },
    fileMemoRules() {
      return [
        (v: string) => !v || v.length <= 100 || StringUtils.format(this.$t("messages.charlen_over_validation") as string, '100'),
      ];
    }
  },
  methods: {
    onUpload(fileInfo?: FileInfo) {
      if (fileInfo) {
        this.uploading = true;
        
        fileInfo.name = fileInfo.file?.name;
        this.$store.dispatch("user/registerFile", fileInfo).then(() => {
          this.uploading = false;
          this.$emit('close-request', null)
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.showErrorAlert = true;
          this.uploading = false;
          this.$emit('close-request', null)
        });
      } else {
        this.$emit('close-request', null)
      }
    }
  },
  components: {
    MessageBox,
  },
  props: {
    isVisible: Boolean,
  }
})
