











































































































































































































































import Vue from 'vue'
import '@/assets/csj-v1.0/style.css'
import AgentDetailDialog from '../../components/user/AgentDetailDialog.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import Breadcrumbs from '../../components/common/Breadcrumbs.vue'
import { StringUtils } from '../../utils/StringUtils'
import { Agent, AgentDevice, Job } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'

export default Vue.extend({
  name: 'AgentDetail',

  data: () => ({
    loading: false,
    agentDetailDialogVisible: false,
    showDeletePrinterMessageBox: false,
    errorMessage: '',
    showErrorAlert: false,
    targetAgents: [],
    selected: [],
    timerId: -1,
    creatingFindPrinterJob: false,
    creatingRegisterPrinterJob: false,
    creatingUnregisterPrinterJob: false,
    options: {},
    agent: null,
    agentDevices: [],
    totalAgentDevices: 0,
    fetchId: 0,

    setting: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'location',
      sortDesc: false,
    },
  }),
  components: {
    AgentDetailDialog,
    MessageBox,
    Breadcrumbs
  },
  computed: {
    headers() {
      return [
        { text: this.$t("agent_detail.column_place"), value: 'location'  },
        { text: this.$t("agent_detail.column_serialno"), value: 'serialNumber'  },
        { text: this.$t("agent_detail.column_deviceid"), value: 'deviceId'  },
        { text: this.$t("agent_detail.column_modelname"), value: 'modelName'  },
        { text: this.$t("agent_detail.column_fwver"), value: 'firmwareId'  },
        { text: this.$t("agent_detail.column_devstatus"), value: 'stateCode'  },
      ]
    },
    breadcrumbs() {
      return [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Dashboard',
          }
        },
        {
          text: this.$t("agent_management.breadcrumbs"),
          exact: true,
          to: {
            name: 'Agents',
          }
        },
        {
          text: this.$t("agent_detail.breadcrumbs"),
        }
      ]
    },
  },
  watch: {
    options: {
      handler () {
        this.fetchAgentDevices(0);
      },
      deep: true,
    }
  },
  methods: {
    getPagenationText(props: { pageStart: string, pageStop: string, itemsLength: string }): string {
      return StringUtils.format(this.$t("agent_detail.table_pagenation") as string, props.pageStart, props.pageStop, props.itemsLength);
    },
    showAgentDetailDialog() {
      (this.targetAgents as Agent[]) = [ (this.agent as unknown) as Agent ];
      this.agentDetailDialogVisible = true;
    },
    closeAgentDetailDialog(param: { agents: Agent[], atOption: string }) {

      if (param && param.agents && param.agents.length) {
        const agent = param.agents[0];
        this.$store.dispatch("user/editAgent", agent).then(() => {
          const job: Job = {
            deviceId: agent.agentId as string,
            command: 'APUT',
            parameter: '',
            atOption: param.atOption,
            retry: 0
          }
          const msg = {
            message: "APUT",
            data: ["エージェントID", agent.agentId]
          };
          this.$store.dispatch("user/createJob", job).then(() => {
            this.$toasted.global.job_create_success(msg);
          }).catch((err) => {
            AppUtils.redirectIfSessionError(this.$router, err as Error);
            this.$toasted.global.job_create_error(msg);
          });
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.errorMessage = this.$t("messages.failed_edit_agent_message") as string;
          this.showErrorAlert = true;
        });
      }
      this.agentDetailDialogVisible = false;
      this.targetAgents = [];
    },
    onFindPrinter() {
      const agentId = this.$route.params.id;

      this.creatingFindPrinterJob = true;

      this.$store.dispatch("user/prepareFindAgentDevices", { agentId: agentId }).then(() => {
        const job: Job = {
          deviceId: agentId,
          command: 'AFIND',
          parameter: '',
          retry: 0
        }
        const msg = {
          message: "AFIND",
          data: ["エージェントID", agentId]
        };
        this.$store.dispatch("user/createJob", job).then(() => {
          this.$toasted.global.job_create_success(msg);
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.$toasted.global.job_create_error(msg);
        }).finally(() => {
          setTimeout(() => {
            this.creatingFindPrinterJob = false;
          }, 1000);
        });
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
        this.errorMessage = this.$t("messages.failed_find_printer") as string;
        this.showErrorAlert = true;
        this.creatingFindPrinterJob = false;
      });
    },
    onRegisterPrinter(isRegister: boolean) {
      const agentId = this.$route.params.id;
      const param = {
        agentId: agentId,
        deviceIdList: (this.selected as AgentDevice[]).map(el => el.deviceId),
        isRegister: isRegister
      };

      if (isRegister) {
        this.creatingRegisterPrinterJob = true;
      } else {
        this.creatingUnregisterPrinterJob = true;
      }

      this.$store.dispatch("user/registerAgentDevices", param).then(() => {
        const job: Job = {
          deviceId: agentId,
          command: 'AREGISTER',
          parameter: '',
          retry: 0
        }
        const msg = {
          message: "AREGISTER",
          data: ["エージェントID", agentId]
        };
        this.$store.dispatch("user/createJob", job).then(() => {
          this.$toasted.global.job_create_success(msg);
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.$toasted.global.job_create_error(msg);
        }).finally(() => {
          setTimeout(() => {
            if (isRegister) {
              this.creatingRegisterPrinterJob = false;
            } else {
              this.creatingUnregisterPrinterJob = false;
            }
          }, 1000);
        });
        this.selected = Object.assign((this.selected as AgentDevice[]).map(dev => (this.agentDevices as AgentDevice[]).find(el => el.deviceId == dev.deviceId)));
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
        this.errorMessage = (isRegister ? this.$t("messages.failed_register_printer_message"): this.$t("messages.failed_unregister_printer_message")) as string;
        this.showErrorAlert = true;
        if (isRegister) {
          this.creatingRegisterPrinterJob = false;
        } else {
          this.creatingUnregisterPrinterJob = false;
        }
      });
      this.showDeletePrinterMessageBox = false;
    },
    isRegisterButtonEnabled() {
      let isEnabled = this.selected.length != 0;
      (this.selected as AgentDevice[]).forEach(elm => {
        isEnabled = (isEnabled && !elm.stateCode);
      });
      return isEnabled && !this.creatingRegisterPrinterJob;
    },
    isUnregisterButtonEnabled() {
      let isEnabled = this.selected.length != 0;
      (this.selected as AgentDevice[]).forEach(elm => {
        isEnabled = (isEnabled && elm.stateCode == 1);
      });
      return isEnabled && !this.creatingUnregisterPrinterJob;
    },
    async getAgentDeviceStatus() {
     //this.agentDevices = await this.$store.dispatch("user/fetchAgentDevices", { agentId: this.$route.params.id });
      this.fetchAgentDevices(0, true);
    },
    fetchAgentDevices(delay: number, disableLoading?: boolean) {
      if (this.fetchId) {
        clearTimeout(this.fetchId);
        this.fetchId = 0;
      }

      this.fetchId = setTimeout(() => {
        const param = {
          agentId: this.$route.params.id,
          options: this.options
        };

        if (!disableLoading) {
          this.loading = true;
        }
        this.$store.dispatch("user/fetchAgentDevices", param).then((resp) => {
          this.agentDevices = resp.devices;
          this.totalAgentDevices = resp.count;
          this.loading = false;
        }).catch((err) => {
            this.loading = false;
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchId = 0;
      }, delay) as unknown as number;
    }
  },
  created() {
    this.$store.dispatch("user/fetchAgents", { agentId: this.$route.params.id }).then((resp) => {
      this.agent = resp.agents[0];
    });

    this.getAgentDeviceStatus();

    const uiSetting = this.$store.getters["user/uiSetting"]('AgentDetail');
    if (uiSetting) {
      this.setting = uiSetting;
    }
  },
  mounted() {
   this.timerId = setInterval(this.getAgentDeviceStatus, 10 * 1000) as unknown as number;    // 10秒
  },
  beforeDestroy() {
    this.$store.dispatch("user/saveUISetting", { key: 'AgentDetail', setting: this.setting});
    if (this.timerId != -1) {
      clearInterval(this.timerId);
    }
  }
})
