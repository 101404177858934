



























































































































import Vue, { PropType } from 'vue'
import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
import { Device } from '../../dataTypes'

export default Vue.extend({
  name: 'NicFWUpdateDialog',

  data: () => ({
    valid: false,
    selectFirmware: 0,
    jobSettingDialogVisible: false,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("nic_fw_update_dlg.column_modelname"), value: 'nicModelName'  },
        { text: this.$t("nic_fw_update_dlg.column_serialno"), value: 'nicSerialNumber'  },
        { text: this.$t("nic_fw_update_dlg.column_fwver"), value: 'nicFirmwareId'  },
        { text: this.$t("nic_fw_update_dlg.column_update_fwver"), value: 'firmVersion', sortable: false  },
      ]
    },
    handleInput(): (event: Event) => void {
      return (event: Event) => {
        if (!event && this.activeElement) {
          this.activeElement.blur();
        }
      }
    },
    activeElement(): HTMLElement | null {
      return document.activeElement as HTMLElement | null;
    }
  },
  methods: {
    showJobSettingDialog() {
      this.jobSettingDialogVisible = true;
    },
    closeJobSettingDialog(param: string) {
      if (param !== null) {
        this.$emit('close-request', { firmwareId: this.firmwares[this.selectFirmware], atOption: param });
      }
      this.jobSettingDialogVisible = false;
    },
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        setTimeout(() => {
          if (!oldVal && newVal && this.$refs.form) {
            (this.$refs.form as HTMLFormElement).reset();
            if (this.firmwares.length) {
              this.selectFirmware = this.firmwares.length - 1;
            }
          }
        }, 0);
      }
    },
  },
  components: {
    JobSettingDialog,
  },
  props: {
    isVisible: Boolean,
    device: Object as PropType<Device>,
    firmwares: Array as PropType<string[]>,
  },
})
