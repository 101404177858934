



























































































































import Vue, { PropType } from 'vue'
import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
import { DeviceFWUpdate } from '../../dataTypes'

export default Vue.extend({
  name: 'PrinterFWUpdateDialog',

  data: () => ({
    valid: false,
    jobSettingDialogVisible: false,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("fw_update_dlg.column_place"), value: 'location', width: 220 },
        { text: this.$t("fw_update_dlg.column_serialno"), value: 'serialNumber'  },
        { text: this.$t("fw_update_dlg.column_deviceid"), value: 'deviceId'  },
        { text: this.$t("fw_update_dlg.column_modelname"), value: 'modelName'  },
        { text: this.$t("fw_update_dlg.column_fwver"), value: 'firmwareId'  },
        { text: this.$t("fw_update_dlg.column_update_fwver"), value: 'firmVersion', sortable: false  },
      ]
    },
    handleInput(): (event: Event) => void {
      return (event: Event) => {
        if (!event && this.activeElement) {
          this.activeElement.blur();
        }
      }
    },
    activeElement(): HTMLElement | null {
      return document.activeElement as HTMLElement | null;
    }
  },
  methods: {
    showJobSettingDialog() {
      this.jobSettingDialogVisible = true;
    },
    closeJobSettingDialog(param: string) {
      if (param !== null) {
        this.$emit('close-request', { devices: this.devices, atOption: param });
      }
      this.jobSettingDialogVisible = false;
    },
  },
  components: {
    JobSettingDialog,
  },
  props: {
    isVisible: Boolean,
    devices: Array as PropType<DeviceFWUpdate[]>,
  },
})
