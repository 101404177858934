

















































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import PrinterDetailDialog from '../../components/user/PrinterDetailDialog.vue'
import NicFWUpdateDialog from '../../components/user/NicFWUpdateDialog.vue'
import NicSendCertDialog from '../../components/user/NicSendCertDialog.vue'
import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
import PrinterSendFileDialog from '../../components/user/PrinterSendFileDialog.vue'
import Breadcrumbs from '../../components/common/Breadcrumbs.vue'
import { BreadcrumbDesc } from '../../components/common/Breadcrumbs.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import { Device, Config, MSWDesc, MSWExtension, Job, Firmware, FileInfo, NicConfig } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'
import { StringUtils } from '../../utils/StringUtils'
import xml2js from 'xml2js';

type EventInfo = {
  [index: string]: { [index: string]: { [index: string]: string } }[]
}

type NicEditConfig = {
  certbase64?: string;
  validitybegin?: string;
  validityend?: string;
  wpaeapcacert64?: string;
  wpaeapusercert64?: string;
}

export default Vue.extend({
  name: 'DeviceMSW',

  data: () => ({
    loading: false,
    jobSettingDialogVisible: false,
    printerDetailDialogVisible: false,
    showErrorAlert: false,
    jobSettingDialogCallback: {},
    targetPrinter: {},
    atOption: '',
    trackJobId: null,
    isParseError: false,
    timerId: -1,
    errorMessage: '',
    netwokCardInfo: { '': [{ '': '' }] },
    mswDefDatas: [],
    capabilities: null,
    modelContentInfo: { modelName: '', usersGuideUrl1: '',  usersGuideUrl2: '', utilityUrl: ''},
    cfgLoading: false,
    nicInfoLoading: false,
    nicConfigData: '',
    valid: false,
    nicFwUpdateDialogVisible: false,
    nicFirmwares: [ '' ],
    nicSendFileDialogVisible: false,
    nicSendCertDialogVisible: false,
    currentUnit: '',
    showUnitChangeMessageBox: false,
    unitChangeCallback: () => { return },
    nicRestoring: false,
    wlanDisabled: true,
    device: null,
    files: [],
    config: null,

    setting: {
      tab: null,
      selectedItem: [ false ],
    },
  }),
  components: {
    PrinterDetailDialog,
    NicFWUpdateDialog,
    NicSendCertDialog,
    JobSettingDialog,
    PrinterSendFileDialog,
    MessageBox,
    Breadcrumbs
  },
  computed: {
    breadcrumbs(): BreadcrumbDesc[] {
      return [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Dashboard',
          }
        },
        {
          text: this.$t("printer_management.breadcrumbs") as string,
          exact: true,
          to: {
            name: 'Devices',
          }
        },
        {
          text: this.$t("msw_setting.breadcrumbs") as string,
        }
      ];
    },
    tabItems(): string[] {
      return [
        this.$t("msw_setting.group_title_msw") as string,
        this.$t("msw_setting.group_title_networkcard") as string,
      ];
    },
    parseMSWData(): MSWDesc[] | null {
      let mswDesc: MSWDesc[] = [];
      let isValidData = true;
      if (this.config && ((this.config as unknown) as Config).editConfigData) {
        const config = ((this.config as unknown) as Config).editConfigData;
        const msw = config.split('\t');

        while (!msw[msw.length - 1]) {
          msw.pop();
        }

        for (let i = 0; i < msw.length;) {
          let mswData: MSWDesc = {};

          mswData.title = msw[i++];
          if (mswData.title.match(/^<MSW\d+-\d+>$/g) == null) {
            isValidData = false;
            break;
          }

          mswData.label = msw[i++];
          mswData.reserved = msw[i++];
          
          let count = parseInt(msw[i++]);
          mswData.defaultIndex = parseInt(msw[i++]);
          mswData.selectIndex = parseInt(msw[i++]);
          mswData.values = [];
          for (let j = 0; j < count; j++) {
            mswData.values.push(msw[i++]);
          }
          const endTag = msw[i++];
          if (endTag != "</MSW>") {
            isValidData = false;
            break;
          }
          mswDesc.push(mswData);
        }

        if (isValidData == false) {
          return null;
        }
      }
      return mswDesc;
    },
    parseBarcodeMSWData(): MSWDesc[] {
      let mswDesc: MSWDesc[] = [];
      if (this.config && ((this.config as unknown) as Config).editConfigData) {
        const config = ((this.config as unknown) as Config).editConfigData;
        const rows = config.split('\r\n');

        while (!rows[rows.length - 1]) {
          rows.pop();
        }

        for (let i = 0; i < rows.length; i++) {
          let mswData: MSWDesc = {};
          let result = rows[i].match(/^(.*)\t:\t(.*)/)

          if (result && result.length > 2) {
            mswData.label = result[1];
            mswData.values = [ result[2].replace(/\t/g, ' ') ];
            mswData.defaultIndex = 0;
            mswData.selectIndex = 0;
            mswDesc.push(mswData);
          }
        }

        let isInch = true;
        if (this.mswDefDatas.length) {
          for (const d of mswDesc) {
            if (d) {
              const parts = (((d.values as string[])[0] + '') as string).split(" ");
              if (parts.length > 1) {
                const unit = parts[parts.length - 1];
                if (unit === 'MM') {
                  isInch = false;
                  break;
                }
              }
            }
          }
        }
        this.makeCapabilities(isInch);

        if (this.capabilities == null) {
          return mswDesc;
        }

        let newMswDesc: MSWDesc[] = [];
        for (const [key, value] of Object.entries(this.capabilities  as unknown as { [index: string]:  {[index: string]: string} })) {
          if (key == '') {
            continue;
          }

          let mswData = mswDesc.find(el => el.label === key);
          let newMswData: MSWDesc = {};
          const cap = value as { [index: string]: string };
          let currentValue: string;
          
          if (mswData) {
            currentValue = (mswData.values as string[])[0];
            if (cap.type === 'I' || cap.type === 'IU' || cap.type === 'F1U' || cap.type === 'F2U') {
              const val = currentValue.split(" ");
              if (val.length > 1) {
                currentValue = val[0];
              }
            }
          } else {
            continue;

            // if (cap.type === 'I' || cap.type === 'IU' || cap.type === 'F1U' || cap.type === 'F2U') {
            //   const digit = cap.type === 'F1U' ? 1: cap.type === 'F2U' ? 2: 0;
            //   const tempValue = digit ? parseFloat(cap.csjvalue) / (10 ** digit): parseFloat(cap.csjvalue);
            //   currentValue = tempValue.toFixed(digit);
            // } else {
            //   currentValue = cap.csjvalue;
            // }
          }
          
          newMswData.ext = {
            type: cap.type,
            unit: cap.unit,
            value: currentValue,
            defaultValue: cap.csjvalue,
          }
          newMswData.label = key;
          newMswData.values = cap.csjdefinition.split('\t');
          if (cap.type === "S") {
            newMswData.defaultIndex = parseInt(cap.csjvalue);
            newMswData.selectIndex = newMswData.values.findIndex((el) => el === currentValue);
            if (newMswData.selectIndex == -1) {
              newMswData.selectIndex = newMswData.defaultIndex;
            }
          } else {
            if (cap.type === 'T') {
              newMswData.ext.defaultValue = this.makeDefaultValue(key, cap);
              newMswData.ext.value = this.makeTextValue(key, cap, currentValue);
            }
            newMswData.defaultIndex = 0;
            newMswData.selectIndex = 0;
          }
          newMswDesc.push(newMswData);
        }
        if (newMswDesc.length) {
          mswDesc = newMswDesc;
        }
      }
      return mswDesc;
    },
    mswItems(): MSWDesc[] | null {
      if (this.device && ((this.device as unknown) as Device).category == 'BC') {
        return this.parseBarcodeMSWData;
      } else {
        return this.parseMSWData;
      }
    },
    handleInput(): (event: Event) => void {
      return (event: Event) => {
        if (!event && this.activeElement) {
          this.activeElement.blur();
        }
      }
    },
    activeElement(): HTMLElement | null  {
      return document.activeElement as HTMLElement | null;
    },
    isRestorable(): boolean {
      return this.device != null && (((this.device as unknown) as Device).category != 'BC' || this.capabilities != null);
    },
    mswExtension(): (mswDesc: MSWDesc) => MSWExtension {
      return (mswDesc: MSWDesc) => {
        return mswDesc.ext as MSWExtension;
      }
    },
    isEnumItemType(): (mswItem: MSWDesc) => boolean {
      return (mswItem: MSWDesc) => {
        return !mswItem.ext || mswItem.ext.type === 'S';
      }
    },
    getUnit(): (mswItem: MSWDesc) => void {
      return (mswItem: MSWDesc) => {
        return mswItem.ext ? mswItem.ext.unit: '';
      }
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getEventLog(): (eventLog) => EventInfo {
      return (eventLog) => {
        const loginfo: EventInfo = eventLog as EventInfo;
        return loginfo;
      }
    },
    rules(): (mswItem: MSWDesc) => ((v: string) => boolean | string)[] {
      return (mswItem) => {
        if (mswItem.ext) {
          const ext = mswItem.ext;
          const values = (mswItem.values as string[]);
          if ((ext.type === 'I' || ext.type === 'IU' || ext.type === 'F1U' || ext.type === 'F2U') && values.length > 1) {
            const digit = ext.type === 'F1U' ? 1: ext.type === 'F2U' ? 2: 0;
            const minValue = digit ? parseFloat(values[0]) / (10 ** digit): parseFloat(values[0]);
            const maxValue = digit ? parseFloat(values[1]) / (10 ** digit): parseFloat(values[1]);
            const minValueStr = minValue.toFixed(digit);
            const maxValueStr = maxValue.toFixed(digit);
            let message = StringUtils.format(this.$t("messages.invalid_range_validation") as string, minValueStr, maxValueStr);
            return [
              (v: string) => !v || !!(v+'').match(/^[+-]?(?:\d+\.?\d*|\.\d+)$/) || message,
              (v: string) => (parseFloat(v) >= minValue && parseFloat(v) <= maxValue) || message,
            ];
          } else if (ext.type === 'T') {
            const label = mswItem.label as string;
            const ruleAsciiOnly = (v: string) => /^[ -~]*$/.test(v) || this.$t("messages.input_valid_string") as string;

            switch (label) {
              case "BT DEVICE NAME":
                return [
                  (v: string) => { v+=''; return (v.length >= 1 && v.length <= 31) || StringUtils.format(this.$t("messages.invalid_range_validation") as string, "1", "31") },
                  ruleAsciiOnly,
                  (v: string) => { v+=''; return (v.charCodeAt(0) !== 0x20 && v.charCodeAt(0) !== 0x22) || this.$t("input_valid_head_string") as string },
                ];
              case "BT PIN CODE":
                return [
                  (v: string) => { v+=''; return (v.length >= 4 && v.length <= 16) || StringUtils.format(this.$t("messages.invalid_range_validation") as string, "4", "16") },
                  ruleAsciiOnly,
                  (v: string) => { v+=''; return (v.charCodeAt(0) !== 0x20 && v.charCodeAt(0) !== 0x22) || this.$t("input_valid_head_string") as string },
                ];
              case "EAP ANONYMOUS NAME":
              case "EAP PASSWORD":
              case "EAP USER NAME":
                return [
                  (v: string) => { v+=''; return (v.length <= 64) || StringUtils.format(this.$t("messages.charlen_over_validation") as string, "64") },
                  ruleAsciiOnly
                ];
              case "HOST NAME":
                return [
                  (v: string) => { v+=''; return (v.length <= 31) || StringUtils.format(this.$t("messages.charlen_over_validation") as string, "31") },
                  ruleAsciiOnly
                ];
              case "GATEWAY ADDRESS":
              case "IPV4 ADDRESS":
              case "IPV4 GATEWAY":
              case "IPV4 SUBNET MASK":
              case "SUBNET MASK":
                return [
                  (v: string) => {
                    v+='';
                    if (!v) {
                      return this.$t("messages.input_valid_ip") as string
                    }
                    const numStr = v.split(".");
                    if (numStr.length !== 4) {
                      return this.$t("messages.input_valid_ip") as string
                    } else {
                      for (let i = 0; i < 4; i++) {
                        const num = Number(numStr[i]);
                        if (numStr[i] === '' || num < 0 || num > 255) {
                          return this.$t("messages.input_valid_ip") as string
                        }
                      }
                    }
                    return true;
                  }
                ];
              case "IPV6 ADDRESS":
              case "IPV6 GATEWAY":
                return [
                  (v: string) => {
                    v+='';
                    if (!v) {
                      return this.$t("messages.input_valid_ip") as string
                    }

                    const numStr = v.split(":");
                    if (numStr.length !== 8) {
                      return this.$t("messages.input_valid_ip") as string
                    } else {
                      for (let i = 0; i < 8; i++) {
                        if (numStr[i].length > 4) {
                          return this.$t("messages.input_valid_ip") as string
                        }
                        const s = StringUtils.zeroPadding(numStr[i], 4);
                        for (let j = 0; j < 4; j++) {
                          if (!/^[0-9A-Fa-f]*$/.test(s)) {
                            return this.$t("messages.input_valid_ip") as string
                          }
                        }
                      }
                    }
                    return true;
                  }
                ];
              case "WLAN PSK":
                return [
                  (v: string) =>  { v+=''; return ((v.length >= 8 && v.length <= 63) || StringUtils.format(this.$t("messages.invalid_range_validation") as string, "8", "63")) },
                  ruleAsciiOnly
                ];
              case "WLAN SSID":
                return [
                  (v: string) =>  { v+=''; return ((v.length <= 32) || StringUtils.format(this.$t("messages.charlen_over_validation") as string, "32")) },
                  ruleAsciiOnly
                ];
              case "WLAN WEP KEY 1":
              case "WLAN WEP KEY 2":
              case "WLAN WEP KEY 3":
              case "WLAN WEP KEY 4":
                return [
                  (v: string) => { v+=''; return ((v.length <= 28) || StringUtils.format(this.$t("messages.charlen_over_validation") as string, "28")) },
                  ruleAsciiOnly
                ];
            }
          }
        }
        return [];
      }
    },
  },
  methods: {
    selecedtMswItemValue(msw: MSWDesc[], num: number): string | undefined {
      if (msw && msw[num] && msw[num].values && msw[num].selectIndex !== undefined) {
        return (msw[num].values as string[])[msw[num].selectIndex as number];
      }
      return '';
    },
    getStatusString(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      if (status == 3) {
        return this.$t("printer_management.list_unknown");
      } else if (status == 1) {
        return this.$t("printer_management.list_normal");
      }
      return this.$t("printer_management.list_error");
    },
    getErrorMessage(device: Device): string {
      return AppUtils.getErrorMessage(device);
    },
    getSeriousErrorMessage(device: Device) {
      return AppUtils.getErrorMessage(device, 0x08);
    },
    toLocalString(str: string) {
      return StringUtils.toLocalString(str);
    },
    // ----- プリンター情報詳細・編集ダイアログ -----
    showPrinterDetailDialog() {
      this.targetPrinter = Object.assign({}, this.device);
      this.printerDetailDialogVisible = true;
    },
    closePrinterDetailDialog(printer: Device) {
      if (printer) {
        this.$store.dispatch("user/editDevice", printer).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.errorMessage = this.$t('messages.failed_edit_printer_setting_message') as string;
          this.showErrorAlert = true;
        });
      }
      this.printerDetailDialogVisible = false;
      this.targetPrinter = {};
    },
    showJobSettingDialog(callback: () => void) {
      this.jobSettingDialogVisible = true;
      if (this.jobSettingDialogCallback) {
        (this.jobSettingDialogCallback as () => void) = callback;
      }
    },
    closeJobSettingDialog(param: string) {
      this.atOption = param;
      if (param !== null) {
        (this.jobSettingDialogCallback as () => void)();
      }
      this.jobSettingDialogVisible = false;
    },
    showNicFwUpdateDialog() {
      this.nicFwUpdateDialogVisible = true;
    },
    closeNicFwUpdateDialog(param: { firmwareId: string, atOption: string }) {
      if (param) {
        const job: Job = {
          deviceId: ((this.device as unknown) as Device).deviceId,
          command: 'NICUPDATE',
          parameter: param.firmwareId,
          atOption: param.atOption,
          retry: 0
        }
        const msg = {
          message: "NICUPDATE",
          data: ["デバイスID", ((this.device as unknown) as Device).deviceId]
        };
        this.$store.dispatch("user/createJob", job).then(() => {
          this.$toasted.global.job_create_success(msg);
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.$toasted.global.job_create_error(msg);
        });
      }

      this.nicFwUpdateDialogVisible = false;
    },
    showNicSendFileDialog() {
      this.$store.dispatch("user/fetchFiles").then((resp) => {
        this.nicSendFileDialogVisible = true;
        this.files = resp.files;
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
      });
    },
    closeNicSendFileDialog(param: { info: FileInfo, atOption: string}) {
      if (param) {
        const job: Job = {
          deviceId: ((this.device as unknown) as Device).deviceId,
          command: 'NICSEND',
          parameter: param.info.fileId,
          atOption: param.atOption,
          retry: 0
        }

        const msg = {
          message: "NICSEND",
          data: ["デバイスID", ((this.device as unknown) as Device).deviceId]
        };
        this.$store.dispatch("user/createJob", job).then(() => {
          this.$toasted.global.job_create_success(msg);
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.$toasted.global.job_create_error(msg);
        });
      }
      this.nicSendFileDialogVisible = false;
    },
    showNicSendCertDialog() {
      this.nicSendCertDialogVisible = true;
    },
    closeNicSendCertDialog(param: { config: NicConfig, atOption: string }) {
      if (param) {
        const obj: NicEditConfig = {};

        if (param.config.validityBegin) {
          obj.validitybegin = param.config.validityBegin;
        }
        if (param.config.validityEnd) {
          obj.validityend = param.config.validityEnd;
        }
        if (param.config.certData) {
          obj.certbase64 = param.config.certData;
        }
        if (param.config.wpaEapCaCert) {
          obj.wpaeapcacert64 = param.config.wpaEapCaCert;
        }
        if (param.config.wpaEapUserCert) {
          obj.wpaeapusercert64 = param.config.wpaEapUserCert;
        }

        const jsonStr = JSON.stringify(obj);
        param.config.editConfigData = StringUtils.encodeBase64(jsonStr);
        param.config.deviceId = ((this.device as unknown) as Device).deviceId;

        this.$store.dispatch("user/setNicConfig", param.config).then(() => {

          const job: Job = {
            deviceId: ((this.device as unknown) as Device).deviceId,
            command: 'NICRESTORE',
            parameter: '',
            atOption: param.atOption,
            retry: 0
          }

          const msg = {
            message: "NICRESTORE",
            data: ["デバイスID", ((this.device as unknown) as Device).deviceId]
          };
          this.$store.dispatch("user/createJob", job).then(async (job: Job) => {
            (this.trackJobId as unknown) = job.jobId as string;
            this.$toasted.global.job_create_success(msg);
            this.nicRestoring = await this.checkNicRestoring();
          }).catch((err) => {
            AppUtils.redirectIfSessionError(this.$router, err as Error);
            this.$toasted.global.job_create_error(msg);
          });
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.errorMessage = this.$t('messages.failed_change_nicsetting_message') as string;
          this.showErrorAlert = true;
        });
      }
      this.nicSendCertDialogVisible = false;
    },
    onBackup() {
      this.showJobSettingDialog(() => {
        const job: Job = {
          deviceId: ((this.device as unknown) as Device).deviceId,
          command: 'BACKUP',
          parameter: '',
          atOption: this.atOption,
          retry: 0
        }

        const msg = {
          message: "BACKUP",
          data: ["デバイスID", ((this.device as unknown) as Device).deviceId]
        };
        this.$store.dispatch("user/createJob", job).then((job: Job) => {
          (this.trackJobId as unknown) = job.jobId as string;
          this.$toasted.global.job_create_success(msg);
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.$toasted.global.job_create_error(msg);
        });
      });
    },
    changeConfig() {
      if (this.mswItems) {
        this.showJobSettingDialog(() => {
          if (this.mswItems) {
            let mswStr;
            if (((this.device as unknown) as Device).category != 'BC') {
              const mswItems = this.mswItems.map((msw) => {
                let str = msw.title + '\t';
                str += msw.label + '\t';
                str += msw.reserved + '\t';
                str += msw.values?.length + '\t';
                str += msw.defaultIndex + '\t';
                str += msw.selectIndex + '\t';
                str += (msw.values as string[]).reduce((prev, current) => {
                  return prev + '\t' + current;
                }) + '\t';
                str += '</MSW>';
                return str;
              });
              mswStr = mswItems.reduce((prev, current) => {
                return prev + '\t' + current;
              }) + '\t';
            } else if (this.isRestorable) {
              let str = String.fromCharCode(0x7e, 0x21, 0x21, 0x0d, 0x0a, 0x02, 0x1b, 0x7e, 0x41, 0x0d, 0x0a, 0x7e, 0x1b, 0x7e, 0x41, 0x0d, 0x0a);
              this.mswItems.forEach((msw) => {
                const ext = msw.ext as MSWExtension;
                str += msw.label + '\t:\t';
                if (ext.type === 'S') {
                  str += (msw.values as string[])[msw.selectIndex as number];
                } else if (ext.type === 'I' || ext.type === 'IU' || ext.type === 'F1U' || ext.type === 'F2U') {
                  str += ext.value;
                  if (ext.unit && ext.unit.length) {
                    str += "\t" + ext.unit;
                  }
                } else {
                  switch (msw.label) {
                    case "IPV4 ADDRESS":
                    case "IPV4 GATEWAY":
                    case "IPV4 SUBNET MASK":
                    case "SUBNET MASK": {
                      const curv4 = ext.value.split(".");
                      for (let i = 0; i < curv4.length; i++) {
                        const num = parseInt(curv4[i]) + '';
                        str += StringUtils.zeroPadding(num, 3);
                        if (i !== curv4.length - 1) {
                          str += ",";
                        }
                      }
                    }
                      break;
                    case "IPV6 ADDRESS":
                    case "IPV6 GATEWAY": {
                        const curv = ext.value.split(":");
                        for (let i = 0; i < curv.length; i++) {
                          const num = parseInt(curv[i], 16);
                          str += num;
                          if (i !== curv.length - 1) {
                            str += ",";
                          }
                        }
                      }
                      break;
                    default:
                      str += ext.value;
                      break;
                  }
                }
                str += '\r\n';
              });
              str += String.fromCharCode(0x45, 0x4e, 0x44, 0x0d, 0x0a);
              mswStr = str;
            }
            this.$store.dispatch("user/setConfig", { device: this.device, config: mswStr } ).then(() => {
              if (((this.device as unknown) as Device).category === 'BC') {
                if (this.mswItems) {
                  const item = this.mswItems.find((el) => el.label === "METRIC/INCH");
                  if (item && item.selectIndex !== undefined) {
                    this.currentUnit = (item.values as string[])[item.selectIndex];
                  }
                }
              }

              const job: Job = {
                deviceId: ((this.device as unknown) as Device).deviceId,
                command: 'RESTORE',
                parameter: '',
                atOption: this.atOption,
                retry: 0
              }
              const msg = {
                message: "RESTORE",
                data: ["デバイスID", ((this.device as unknown) as Device).deviceId]
              };
              this.$store.dispatch("user/createJob", job).then(() => {
                this.$toasted.global.job_create_success(msg);
              }).catch((err) => {
                AppUtils.redirectIfSessionError(this.$router, err as Error);
                this.$toasted.global.job_create_error(msg);
              });
            }).catch((err) => {
              AppUtils.redirectIfSessionError(this.$router, err as Error);
              this.errorMessage = this.$t('messages.failed_change_msw_message') as string;
              this.showErrorAlert = true;
            });
          }
        });
      }
    },
    onChangeConfig() {
      if (this.mswItems) {
        let showChangeUnitMessage = false;

        if (((this.device as unknown) as Device).category === 'BC') {
          this.mswItems.forEach((el) => {
            if (el.ext) {
              const ext: MSWExtension = el.ext;
              if (ext.type === 'I' || ext.type === 'IU') {
                ext.value = parseInt(ext.value) + '';
              } else if (ext.type === 'F1U' || ext.type === 'F2U') {
                const v = parseFloat(ext.value) + '';
                const digits = ext.type === 'F1U' ? 1: 2;
                const parts = v.split(".");
                if (parts.length === 1) {
                  ext.value = `${parts[0]}.${StringUtils.zeroPaddingR(0, digits)}`;
                } else if (parts.length >= 2) {
                  ext.value = `${parts[0]}.${StringUtils.zeroPaddingR(parts[1], digits)}`;
                }
              }
            }
          });

          const item = this.mswItems.find((el) => el.label === "METRIC/INCH");
          if (item && item.selectIndex !== undefined) {
            const unit = (item.values as string[])[item.selectIndex];
            if (this.currentUnit && unit && this.currentUnit !== unit) {
              showChangeUnitMessage = true;
            }
          }
        }

        if (showChangeUnitMessage) {
          this.showUnitChangeMessageBox = true;
          this.unitChangeCallback = () => {
            this.showUnitChangeMessageBox = false;
            this.changeConfig();
          }
        } else {
          this.changeConfig();
        }
      }        
    },
    onBackupNicSetings() {
      this.showJobSettingDialog(() => {
        const job: Job = {
          deviceId: ((this.device as unknown) as Device).deviceId,
          command: 'NICBACKUP',
          parameter: '',
          atOption: this.atOption,
          retry: 0
        }

        const msg = {
          message: "NICBACKUP",
          data: ["デバイスID", ((this.device as unknown) as Device).deviceId]
        };
        this.$store.dispatch("user/createJob", job).then((job: Job) => {
          (this.trackJobId as unknown) = job.jobId as string;
          this.$toasted.global.job_create_success(msg);
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
          this.$toasted.global.job_create_error(msg);
        });
      });
    },
    onExport() {
      const blob = new Blob([this.nicConfigData], { type: 'application/xml' });

      if ("showSaveFilePicker" in window)  {
        (async () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const fh = await window.showSaveFilePicker({ suggestedName: 'nic.xml' });
          const stream = await fh.createWritable();
          await stream.write(blob);
          await stream.close();
        })();
      } else {
        let el = document.createElement('a');
        document.body.appendChild(el);
        el.href = window.URL.createObjectURL(blob);
        el.download = 'nic.xml';
        el.click();
        document.body.removeChild(el);
      }
    },
    async trackJob() {
      if (this.trackJobId) {
        this.$store.dispatch("user/fetchJobs", { jobId: this.trackJobId }).then((resp) => {
          const targetJob = resp.jobs && resp.jobs.length ? resp.jobs[0]: null;
          if (targetJob && targetJob.result !== null) {
            // ジョブ終了
            if (targetJob.result == 0) {
              if (targetJob.command === 'BACKUP') {
                // 2秒後に設定を取得し、画面更新する
                this.cfgLoading = true;
                setTimeout(() => {
                  this.$store.dispatch("user/fetchConfig", this.device);
                  this.cfgLoading = false;

                  if (((this.device as unknown) as Device).category === 'BC') {
                    if (this.mswItems) {
                      const item = this.mswItems.find((el) => el.label === "METRIC/INCH");
                      if (item && item.selectIndex !== undefined) {
                        this.currentUnit = (item.values as string[])[item.selectIndex];
                      }
                    }
                  }

                }, 2000);
              } else if (targetJob.command === 'NICBACKUP') {
                // 2秒後に設定を取得し、画面更新する
                this.netwokCardInfo =  { '': [{ '': '' }] }
                this.nicInfoLoading = true;
                this.nicConfigData = '';
                setTimeout(() => {
                  try {
                    this.$store.dispatch("user/fetchNicConfig", this.device).then((config: Config) => {
                      this.nicConfigData = config.configData as string;
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      this.netwokCardInfo = this.setupNicInfo(this.nicConfigData);
                      // this.setting.selectedItem = new Array(Object.keys(this.netwokCardInfo).length);

                    });
                  } catch (err) {
                    console.log(err);
                  }
                  this.nicInfoLoading = false;
                }, 2000);
              }
            }
            this.trackJobId = null;
          }
        }).catch(() => {
            this.trackJobId = null;
        });
      }

      // NICRESTOREを監視
      this.nicRestoring = await this.checkNicRestoring();
    },
    makeDefaultValue(label: string, cap: { [index: string]: string }): string {
      let strValue = cap.csjvalue;

      switch (label) {
        case "IPV4 ADDRESS":
        case "IPV4 GATEWAY":
        case "IPV4 SUBNET MASK":
        case "SUBNET MASK": {
            let dat = [ "0", "0", "0", "0" ];
            if (cap && cap.csjvalue) {
              const defv = cap.csjvalue.split(",");
              for (let i = 0; i < Math.min(4, defv.length); i++) {
                if (defv[i].length) {
                  dat[i] = parseInt(defv[i]) + '';
                }
              }
            }
            strValue = dat.join(".");
          }
          break;
        case "IPV6 ADDRESS":
        case "IPV6 GATEWAY": {
            let dat = [ "0000", "0000", "0000", "0000", "0000", "0000", "0000", "0000" ];
            if (cap && cap.csjvalue) {
              const defv = cap.csjvalue.split(",");
              for (let i = 0; i < Math.min(8, defv.length); i++) {
                dat[i] = StringUtils.zeroPadding(defv[i], 4);
              }
            }
            strValue = dat.join(":");
          }
          break;
      }
      return strValue;
    },
    makeTextValue(label: string, cap: { [index: string]: string }, value: string): string {
      let strValue = cap.csjvalue;

      switch (label) {
        case "IPV4 ADDRESS":
        case "IPV4 GATEWAY":
        case "IPV4 SUBNET MASK":
        case "SUBNET MASK": {
            let dat = this.makeDefaultValue(label, cap).split(".");
            if (value) {
              const curv = value.split(",");
              for (let i = 0; i < Math.min(4, curv.length); i++) {
                if (curv[i].length) {
                  dat[i] = parseInt(curv[i]) + '';
                }
              }
            }
            strValue = dat.join(".");
          }
          break;
        case "IPV6 ADDRESS":
        case "IPV6 GATEWAY": {
            let dat = this.makeDefaultValue(label, cap).split(":");
            if (value) {
              const curv = value.split(",");
              for (let i = 0; i < Math.min(4, curv.length); i++) {
                dat[i] = StringUtils.zeroPadding(curv[i], 4);
              }
            }
            strValue = dat.join(":");
          }
          break;
      }
      return strValue;
    },
    makeCapabilities(isInch: boolean) {
      if (this.mswDefDatas) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.capabilities = {};
        this.mswDefDatas.forEach((el: { [index: string]: string }) => {
          if (isInch) {
            if (el.unit !== "MM") {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.capabilities[el['csjkey']] = el;
            }
          } else {
            if (el.unit !== "INCH") {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.capabilities[el['csjkey']] = el;
            }
          }
        });
      } else {
        this.capabilities = null;
      }
    },
    setupNicInfo(config: string) {
      const strXml = config;
      const parser = new xml2js.Parser();
      let netwokCardInfo: { [index: string]: { [index: string]: string }[] } = { '': [{ '': '' }] };
      
      parser.parseString(strXml, (err, result) => {
        if (result && result["s:Envelope"]) {
          const data = result["s:Envelope"]["s:Body"][0]["GetConfigResponse"][0];

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          netwokCardInfo = Object.keys(data).
            filter((key) => key !== "$" && key !== "MessageID" && key !== "Response").
            reduce((cur, key) => { return Object.assign(cur, { [key]: data[key] })}, {});
        }
      });

      return netwokCardInfo;
    },
    async checkNicRestoring() {
      let nicRestoring = false;
      const resp = await this.$store.dispatch("user/fetchJobs", { command: 'NICRESTORE' });
      if (resp.jobs && resp.jobs.length) {
        nicRestoring = true;
      }
      return nicRestoring;
    }
  },
  async created() {
    this.loading = true;

    const uiSetting = this.$store.getters["user/uiSetting"]('DeviceMSW');
    if (uiSetting) {
      this.setting = uiSetting;
    }

    // デバイス情報の取得
    if (!this.device) {
      const resp = await this.$store.dispatch("user/fetchDevices", { deviceId: this.$route.params.id });
      this.device = resp.devices[0];
    }

    if (this.device) {
      if (!((this.device as unknown) as Device).nicModelName && this.setting.tab === 1) {
        this.setting.tab = null;
      } 

      if (((this.device as unknown) as Device).nicModelName) {
        const found = [...((this.device as unknown) as Device).nicModelName.matchAll(/IF\d+-(\D+)\d+/g)];
        if (found[0].length >= 2) {
          if (found[0][1] === 'WFx') {
            this.wlanDisabled = false;
          }
        }
      }
    }

    // ヘルプ情報の取得
    try {
      this.modelContentInfo = await this.$store.dispatch("user/getModelContentInfo", { modelName: ((this.device as unknown) as Device).modelName });
    } catch (err) {
      this.modelContentInfo = { modelName: '', usersGuideUrl1: '',  usersGuideUrl2: '', utilityUrl: ''}
    }

    // MSW情報の取得
    try {
      this.cfgLoading = true;
      const emptyConfig: Config = {
        deviceId: ((this.device as unknown) as Device).deviceId as string,
        configData: "",
        editConfigData: ""
      };
      this.$store.commit("user/updateConfig", emptyConfig);
      this.config = await this.$store.dispatch("user/fetchConfig", this.device);

      const res = await this.$store.dispatch("user/getCmddDefData", { modelname: ((this.device as unknown) as Device).modelName });
      if (res.length) {
        this.mswDefDatas = res;
      }
      this.cfgLoading = false;
    } catch (err) {
      this.cfgLoading = false;
      console.log(err);
    }

    // NIC情報の取得
    try {
      this.nicInfoLoading = true;
      this.nicConfigData = '';

      const res = await this.$store.dispatch("user/fetchNicConfig", this.device);
      this.nicConfigData = res.configData as string;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.netwokCardInfo = this.setupNicInfo(this.nicConfigData);
      // this.setting.selectedItem = new Array(Object.keys(this.netwokCardInfo).length);

      const nicModelName = ((this.device as unknown) as Device).nicModelName;
      const supportedFirmwares = await this.$store.dispatch("user/fetchSupportedFirmwares", { modelNames: [ nicModelName ] });
      this.nicFirmwares = supportedFirmwares ? supportedFirmwares[nicModelName].map((el: Firmware) => el.firmwareId): [];

      if (((this.device as unknown) as Device).category === 'BC') {
        if (this.mswItems) {
          const item = this.mswItems.find((el) => el.label === "METRIC/INCH");
          if (item && item.selectIndex !== undefined) {
            this.currentUnit = (item.values as string[])[item.selectIndex];
          }
        }
      }

      this.nicInfoLoading = false;
    } catch (err) {
      this.nicInfoLoading = false;
      console.log(err);
    }

    this.loading = false;
  },
  async mounted() {
    this.nicRestoring = await this.checkNicRestoring();
    this.timerId = setInterval(this.trackJob, 10 * 1000) as unknown as number;    // 10秒
  },
  beforeDestroy() {
    this.$store.dispatch("user/saveUISetting", { key: 'DeviceMSW', setting: this.setting});

    if (this.timerId != -1) {
      clearInterval(this.timerId);
    }
  }
})
