




























































































import Vue, { PropType } from 'vue'
import '@/assets/csj-v1.0/style.css'

export default Vue.extend({
  name: 'ColumnSettingDialog',
  data: () => ({
    selected: [],
    valid: false,
  }),
  computed: {
    editColumns(): { id: string, label: string, select: boolean, disabled: boolean }[] {
      return this.columns.filter(el => !el.disabled);
    }
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal) {
          if (this.$refs.form) {
            (this.$refs.form as HTMLFormElement).reset();
          }
          (this.selected as string[]) = this.columns.filter(elem => elem.select).map(el => el.id);
        }
      }
    }
  },
  methods: {
    onOk() {
      this.$emit('close-request', this.selected);
    }
  },
  props: {
    isVisible: Boolean,
    columns: {
      type: Array as PropType<{ id: string, label: string, select: boolean, disabled: boolean }[]>
    }
  }
})
