


















































































































import Vue, { PropType } from 'vue'
import { StringUtils } from '../../utils/StringUtils'
import { Device } from '../../dataTypes'

export default Vue.extend({
  name: 'PrinterDetailDialog',

  data: () => ({
    valid: false,
  }),
  computed: {
    locationRules() {
      return [
        (v: string) => !v || v.length <= 200 || StringUtils.format(this.$t("messages.charlen_over_validation") as string, '200'),
      ];
    },
    serialNumberRules() {
      return [
        (v: string) => !v || v.length <= 100 || StringUtils.format(this.$t("messages.charlen_over_validation") as string, '100'),
      ];
    }
  },
  props: {
    isVisible: Boolean,
    device: Object as PropType<Device>,
  }
})
