



































































































































































































import Vue from 'vue'
import '@/assets/csj-v1.0/style.css'
import AgentDetailDialog from '../../components/user/AgentDetailDialog.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import Breadcrumbs from '../../components/common/Breadcrumbs.vue'
import { StringUtils } from '../../utils/StringUtils'
import { Agent, Job, AgentUISetting } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'

interface IAgentDetailDialog extends Vue {
  setup(): void
}

export default Vue.extend({
  name: 'Agents',

  data: () => ({
    loading: false,
    agentDetailDialogVisible: false,
    showErrorAlert: false,
    targetAgent: [],
    options: {},
    agents: [],
    totalAgents: 0,
    fetchId: 0,
    selectedAgents: [],

    setting: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'location',
      sortDesc: false,
      filterPanel: null,
      locationFilterValue: '',
      agentIdFilterValue: ''
    },
  }),
  components: {
    AgentDetailDialog,
    MessageBox,
    Breadcrumbs
  },
  computed: {
    agentDetailDialog(): IAgentDetailDialog {
      return this.$refs.agentDetailDialog as IAgentDetailDialog;
    },
    headers: function (): { text: string, value: string, filter?: (arg: string) => boolean, align?: string, sortable?: boolean, width?: number }[] {
      return [
        { text: this.$t("agent_management.column_place") as string, value: 'location'  },
        { text: this.$t("agent_management.column_agentid") as string, value: 'agentId'  },
        { text: this.$t("agent_management.column_job_interval") as string, value: 'jobIntervalSec' },
        { text: this.$t("agent_management.column_status_interval") as string, value: 'intervalSec' },
        { text: this.$t("agent_management.column_detail") as string, value: 'detail', align: 'center', sortable: false, width: 60  },
      ]
    },
    menuItems() {
      return [
        { label: this.$t("agent_management.menuitem_change_setting"), icon: "icon-Agent_change_icon" },
      ]
    },
    breadcrumbs() {
      return [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Dashboard',
          }
        },
        {
          text: this.$t("agent_management.breadcrumbs"),
        }
      ]
    },
  },
  watch: {
    options: {
      handler () {
        this.fetchAgents(0);
      },
      deep: true,
    },
    'options.page': {
      handler () {
        this.selectedAgents = [];
      }
    },
    'options.itemsPerPage': {
      handler () {
        this.selectedAgents = [];
      }
    },
    'setting.locationFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchAgents(500);
      }
    },
    'setting.agentIdFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchAgents(500);
      }
    },
  },
  methods: {
    getPagenationText(props: { pageStart: string, pageStop: string, itemsLength: string }): string {
      return StringUtils.format(this.$t("agent_management.table_pagenation") as string, props.pageStart, props.pageStop, props.itemsLength);
    },
    showAgentDetailDialog() {
      (this.targetAgent as Agent[]) = (this.selectedAgents as Agent[]).map((agent) => {
        const target = (this.agents as Agent[]).find(el => el.agentId == agent.agentId);
        return Object.assign({}, target);
      });
      this.agentDetailDialogVisible = true;
    },
    closeAgentDetailDialog(param: { agents: Agent[], atOption: string }) {
      this.agentDetailDialogVisible = false;
      this.targetAgent = [];

      if (param && param.agents) {
        for (let agent of param.agents) {
          this.$store.dispatch("user/editAgent", agent).then(() => {
            const job: Job = {
              deviceId: agent.agentId as string,
              command: 'APUT',
              parameter: '',
              atOption: param.atOption,
              retry: 0
            }
            const msg = {
              message: "APUT",
              data: ["エージェントID", agent.agentId]
            };
            this.$store.dispatch("user/createJob", job).then(() => {
              this.$toasted.global.job_create_success(msg);
            }).catch((err) => {
              AppUtils.redirectIfSessionError(this.$router, err as Error);
              this.$toasted.global.job_create_error(msg);
            });
          }).catch((err) => {
            AppUtils.redirectIfSessionError(this.$router, err as Error);
            this.showErrorAlert = true;
          });
        }
      }
    },
    onMenuSelect(itemIndex: number) {
      setTimeout(() => {
        switch (itemIndex) {
          case 0:
            this.showAgentDetailDialog();
        }
      }, 50);
    },
    isMenuItemDisabled(itemIndex: number) {
        switch (itemIndex) {
          case 0:
            return !this.selectedAgents.length;            
          default:
            return true;
        }
    },
    onClearFilter() {
      (this.setting as AgentUISetting).locationFilterValue = '';
      (this.setting as AgentUISetting).agentIdFilterValue = '';
    },
    fetchAgents(delay: number) {
      if (this.fetchId) {
        window.clearTimeout(this.fetchId);
        this.fetchId = 0;
      }

      this.fetchId = window.setTimeout(() => {
        const param = {
          locationFilterValue: this.setting.locationFilterValue,
          agentIdFilterValue: this.setting.agentIdFilterValue,
          options: this.options
        };

        this.loading = true;
        this.$store.dispatch("user/fetchAgents", param).then((resp) => {
          this.agents = resp.agents;
          this.totalAgents = resp.count;
          this.loading = false;
        }).catch((err) => {
            this.loading = false;
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchId = 0;
      }, delay);
    }
  },
  created() {
    const uiSetting = this.$store.getters["user/uiSetting"]('Agents');
    if (uiSetting) {
      this.setting = uiSetting;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("user/saveUISetting", { key: 'Agents', setting: this.setting});
  }
})
