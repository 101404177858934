
























































































































































































































































































































































import Vue from 'vue'
import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import { NicConfig } from '../../dataTypes'
import { StringUtils } from '../../utils/StringUtils'

export default Vue.extend({
  name: 'NicSendCertDialog',

  data: () => ({
    valid: false,
    selected: [ false, false, false, false, false ],
    jobSettingDialogVisible: false,
    showErrorAlert: false,
    showConfirmAlert: false,

    showValidityNotBeforeDateMenu: false,
    validityNotBeforeDate: (new Date()).toISOString().substring(0, 10),
    showValidityNotAfterDateMenu: false,
    validityNotAfterDate: (new Date()).toISOString().substring(0, 10),
    caSignedCertFile: undefined,
    wpaEapImportCaCertFile: undefined,
    wpaEapImportUserCertFile: undefined,
  }),
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        setTimeout(() => {
          if (!oldVal && newVal && this.$refs.form) {
            (this.$refs.form as HTMLFormElement).reset();
            this.selected = [];
            (this.$refs.form as HTMLFormElement).validate();

            const date = new Date();
            this.validityNotBeforeDate = date.toISOString().substring(0, 10);

            date.setFullYear(date.getFullYear() + 1);
            this.validityNotAfterDate = date.toISOString().substring(0, 10);
          }
        }, 0);
      }
    }
  },    
  props: {
    isVisible: Boolean,
    wlanDisabled: Boolean
  },
  components: {
    JobSettingDialog,
    MessageBox,
  },
  computed: {
    fileRules(): (index: number) => ((v: string) => boolean | string)[] {
      return (index) => {
        return [
          (v: string) => !this.selected[index] || !!v || this.$t("messages.register_file_validation") as string,
        ];
      }
    },
    isValid() {
      return (this.valid && this.selected.filter(el=>el!==false).length !== 0);
    }
  },
  methods: {
    dayFormat(date: Date): number {
      return new Date(date).getDate()
    },
    showJobSettingDialog() {
      if (this.selected[0] && this.selected[1]) {
        const validityNotBeforeDate = new Date(this.validityNotBeforeDate);
        const validityNotAfterDate = new Date(this.validityNotAfterDate);
        if (validityNotBeforeDate.getTime() >= validityNotAfterDate.getTime()) {
          this.showErrorAlert = true;
          return;
        }
      }

      this.jobSettingDialogVisible = true;
    },
    readFile(file: File) {
      let certData: string;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          certData = StringUtils.encodeBase64(reader.result as string);
          resolve(certData);
        };
        reader.onerror = (event) => {
          reject(event);
        };
        reader.readAsText(file);
      });
    },
    async closeJobSettingDialog(param: string) {
      if (param !== null) {
        let config: NicConfig = {}
        if (this.selected[0]) {
          config.validityBegin = this.validityNotBeforeDate.replace(/-/g, '/');
        }
        if (this.selected[1]) {
          config.validityEnd = this.validityNotAfterDate.replace(/-/g, '/');
        }
        try {
          if (this.selected[2]) {
            const file = this.caSignedCertFile as unknown as File;
            config.certData = await this.readFile(file) as string;
          }
          if (this.selected[3]) {
            const file = this.wpaEapImportCaCertFile as unknown as File;
            config.wpaEapCaCert = await this.readFile(file) as string;
          }
          if (this.selected[4]) {
            const file = this.wpaEapImportUserCertFile as unknown as File;
            config.wpaEapUserCert = await this.readFile(file) as string;
          }
        } catch (err) {
          console.log(err);
        }
        this.$emit('close-request', { config: config, atOption: param });
      }
      this.jobSettingDialogVisible = false;
    },
    selectFile() {
      (this.$refs.form as HTMLFormElement).validate();
    },
    onSendNicCert() {
      this.showConfirmAlert = false;
      this.showJobSettingDialog();
    }
  }
})
