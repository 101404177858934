


























































































































































































import Vue from 'vue'
import { StringUtils } from '../../utils/StringUtils'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default Vue.extend({
  name: 'JobSettingDialog',

  data: () => ({
    valid: false,
    showDateMenu: false,
    launchMode: 0,
    launchDate: "",
    launchTime: "",
    timeout: "",
  }),
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal && this.$refs.form) {
          (this.$refs.form as HTMLFormElement).reset();
        }
      }
    },
  },
  props: {
    isVisible: Boolean,
    clientKey: String
  },
  components: {
      'vue-timepicker': VueTimepicker,
  },
  computed: {
    timeoutRules(): ((v: string) => boolean | string)[] {
      return [
        (v: string) => !v || String(v).match(/^\d+$/) !== null || this.$t("messages.input_valid_string_number") as string,
        (v: string) => v === null || v.length <= 100 || StringUtils.format(this.$t("messages.charlen_over_validation") as string, '100')
      ];
    },
  },
  methods: {
    dayFormat(date: Date): number {
      return new Date(date).getDate()
    },
    getDefaultDateTime(n: number) {
      const dt = new Date();
      dt.setHours(dt.getHours() + n); // n時間後
      return dt;
    },
    getDateString(dt: Date) {
      const yyyy = ("0" + dt.getFullYear()).slice(-4);
      const mm = ("0" + (dt.getMonth() + 1)).slice(-2);
      const dd = ("0" + dt.getDate()).slice(-2);
      return `${yyyy}-${mm}-${dd}`;
    },
    getTimeString(dt: Date) {
      const hh = ("0" + dt.getHours()).slice(-2);
      const mm = ("0" + dt.getMinutes()).slice(-2);
      return `${hh}:${mm}`;
    },
    onChangeLaunchMode() {
      if (this.launchMode == 0) {
        this.launchDate = "";
      } else {
        const dt = this.getDefaultDateTime(1);
        this.launchDate = this.getDateString(dt);
        this.launchTime = this.getTimeString(dt);
      }
    },
    onClose(isOK: boolean) {
      let atOption = null;
      if (isOK) {
          if (this.launchMode != 0) {
            atOption = (this.launchDate + this.launchTime).replace(/[-:]/g, "");
            if (this.timeout) {
              atOption = atOption + ' ' + this.timeout;
            }
          } else {
            atOption = "";
          }
      }
      this.$emit('close-request', atOption);
    }
  },
  mounted() {
    const dt = this.getDefaultDateTime(1);
    this.launchDate = "";
    this.launchTime = this.getTimeString(dt);
  }
})
