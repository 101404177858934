
















































































































































import Vue, { PropType } from 'vue'
import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
import { StringUtils } from '../../utils/StringUtils'
import { Agent } from '../../dataTypes'

export default Vue.extend({
  name: 'AgentDetailDialog',

  data: () => ({
    valid: false,
    isMultiple: false,
    location: '',
    jobIntervalSec: '',
    intervalSec: '',
    jobSettingDialogVisible: false,
  }),
  computed: {
    locationRules() {
      return [
        (v: string) => !v || v.length <= 100 || StringUtils.format(this.$t("messages.charlen_over_validation") as string, '100')
      ];
    },
    jobIntervalRules() {
      return [
        (v: string) => !v || String(v).match(/^\d+$/) !== null || this.$t("messages.input_valid_string_number"),
        (v: string) => !v || (parseInt(v) >= 5 && parseInt(v) <= 999) || StringUtils.format(this.$t("messages.invalid_range_validation") as string, '5', '999'),
      ];
    },
    intervalRules() {
      return [
        (v: string) => !v || String(v).match(/^\d+$/) !== null || this.$t("messages.input_valid_string_number"),
        (v: string) => !v || (parseInt(v) >= 5 && parseInt(v) <= 999) || StringUtils.format(this.$t("messages.invalid_range_validation") as string, '5', '999'),
      ]
    },
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal && this.$refs.form) {
          (this.$refs.form as HTMLFormElement).reset();
        }
      }
    },
    agents: {
      handler: function(newVal, oldVal) {
        if (oldVal.length == 0 && newVal.length) {
          this.$nextTick(() => {
            this.setup();
          });
        }
      }
    }
  },
  props: {
    isVisible: Boolean,
    agents: Array as PropType<Agent[]>,
  },
  methods: {
    setup() {
      this.isMultiple = false;
      this.location = '';
      this.jobIntervalSec = '';
      this.intervalSec = '';

      if (this.agents && this.agents.length) {
        if (this.agents.length > 1) {
          this.isMultiple = true;
        }
        let locations = new Set();
        this.agents.forEach((el) => {
          locations.add(el.location);
        });
        if (locations.size === 1) {
          this.location = locations.values().next().value;
        }

        let jobIntervalSecs = new Set();
        this.agents.forEach((el) => {
          jobIntervalSecs.add(el.jobIntervalSec);
        });
        if (jobIntervalSecs.size === 1) {
          this.jobIntervalSec = jobIntervalSecs.values().next().value;
        }
      
        let intervalSecs = new Set();
        this.agents.forEach((el) => {
          intervalSecs.add(el.intervalSec);
        });
        if (intervalSecs.size === 1) {
          this.intervalSec = intervalSecs.values().next().value;
        }
      }
    },
    showJobSettingDialog() {
      this.jobSettingDialogVisible = true;
    },
    closeJobSettingDialog(param: string) {
      if (param !== null) {
        const agents = this.agents.map((el: Agent) => {
          return Object.assign(el, {
            location: this.location ? this.location: el.location,
            jobIntervalSec: this.jobIntervalSec ? this.jobIntervalSec: el.jobIntervalSec,
            intervalSec: this.intervalSec ? this.intervalSec: el.intervalSec,
          });
        });
        this.$emit('close-request', { agents: agents, atOption: param });
      }
      this.jobSettingDialogVisible = false;
    },
  },
  components: {
    JobSettingDialog,
  },
})
