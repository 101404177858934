






























































































import Vue from 'vue'
import * as appConfig from '../../appConfig'

export default Vue.extend({
  name: 'VersionDialog',

  data: () => ({
    version: appConfig.version,
  }),
  methods: {
  },
  computed: {
  },
  props: {
    isVisible: Boolean,
  }
})
