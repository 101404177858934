






































































































































































































































































































































































































import Vue from 'vue'
import '@/assets/csj-v1.0/style.css'
import ColumnSettingDialog from '../../components/common/ColumnSettingDialog.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import Breadcrumbs from '../../components/common/Breadcrumbs.vue'
import { StringUtils } from '../../utils/StringUtils'
import { Job, JobUISetting } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'

type ColumnIdList = {
  id: string;
  label: string;
  select: boolean;
}

type DataSetting = {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  filterPanel: number | null;
  filterIndex: number;
  locationFilterValue: string;
  deviceIdFilterValue: string;
}

type DataType = {
  loading: boolean;
  showCancelJobMessageBox: boolean;
  showErrorAlert: boolean;
  errorMessage: string;
  columnSettingDialogVisible: boolean;
  targetJob: Job | null;
  columnIdList: ColumnIdList[];
  mouseOverItem: string | null;
  copyItem: string | null;
  setting: DataSetting;
  options: unknown;
  jobs: Job[];
  totalJobs: number;
  fetchId: number;
}

export default Vue.extend({
  name: 'JobQueue',

  data(): DataType {
    return {
      loading: false,
      showCancelJobMessageBox: false,
      showErrorAlert: false,
      errorMessage: '',
      columnSettingDialogVisible: false,
      targetJob: null,
      columnIdList: [],
      mouseOverItem: null,
      copyItem: null,
      options: {},
      jobs: [],
      totalJobs: 0,
      fetchId: 0,

      setting: {
        page: 1,
        itemsPerPage: 15,
        sortBy: 'lastUpdate',
        sortDesc: true,
        filterIndex: 0,
        filterPanel: null,
        locationFilterValue: '',
        deviceIdFilterValue: '',
      }
    }
  },
  components: {
    ColumnSettingDialog,
    MessageBox,
    Breadcrumbs
  },
  computed: {
    headers: function (): { text: string, value: string, width?: string, filter?: (arg: number) => boolean, align?: string, sortable?: boolean, disabled?: boolean, isOption?: boolean }[] {
      return [
        { text: this.$t("job_queuing.column_place") as string, width: "140", value: 'location' },
        { text: this.$t("job_queuing.column_jobid") as string, width: "100", value: 'jobId', isOption: true  },
        { text: this.$t("job_queuing.column_deviceid") as string, width: "140", value: 'deviceId', disabled: true  },
        { text: this.$t("job_queuing.column_command") as string, width: "100", value: 'command', disabled: true  },
        { text: this.$t("job_queuing.column_parameter") as string, width: "120", value: 'parameter', isOption: true  },
        { text: this.$t("job_queuing.column_sattus") as string, width: "110", value: 'status', align: 'center', disabled: true  },
        { text: this.$t("job_queuing.column_atoption") as string, width: "140", value: 'atOption'  },
        { text: this.$t("job_queuing.column_agentjobid") as string, width: "170", value: 'agentJobId', isOption: true  },
        { text: this.$t("job_queuing.column_control") as string, width: "130", value: 'control', align: 'center'  },
        { text: this.$t("job_queuing.column_gatewayid") as string, width: "140", value: 'ioGatewayId', isOption: true  },
        { text: this.$t("job_queuing.column_register_date") as string, width: "170", value: 'lastUpdate', disabled: true  },
        { text: this.$t("job_queuing.column_cancel_job") as string, width: "80", value: 'detail', align: 'center', sortable: false, disabled: true  },
      ];
    },
    customHeaders: function (): { text: string, value: string, filter?: (arg: number) => boolean, align?: string, sortable?: boolean }[] {
      return this.headers.filter(elem => {
        const col = this.columnIdList.find((el) => el.id == elem.value);
        return col && col.select;
      });
    },
    filterItems() {
      return [
        this.$t("job_queuing.filter_all"),
        this.$t("job_queuing.filter_waiting"),
        this.$t("job_queuing.filter_executing"),
      ]
    },
    breadcrumbs() {
      return [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Dashboard',
          }
        },
        {
          text: this.$t("job_queuing.breadcrumbs"),
          disabled: false
        }
      ]
    },
  },
  watch: {
    options: {
      handler () {
        this.fetchJobs(0);
      },
      deep: true,
    },
    'setting.locationFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchJobs(500);
      }
    },
    'setting.deviceIdFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchJobs(500);
      }
    },
    'setting.filterIndex': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchJobs(0);
      }
    }
  },
  methods: {
    getPagenationText(props: { pageStart: string, pageStop: string, itemsLength: string }): string {
      return StringUtils.format(this.$t("job_queuing.table_pagenation") as string, props.pageStart, props.pageStop, props.itemsLength);
    },
    doRemove(item: Job | null) {
      this.$store.dispatch("user/deleteJob", item).then((resp) => {
        this.jobs = this.jobs.filter(el => el.jobId != resp);
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
        if (parseInt((err as Error).message) === -5) {
          this.errorMessage = this.$t('messages.reject_job_cancellation_message') as string;
        } else {
          this.errorMessage = this.$t('messages.failed_cancel_job_message') as string;
        }
        this.showErrorAlert = true;
      });
      this.showCancelJobMessageBox = false;
      this.targetJob = null;
    },
    shrinkStr(str?: string) {
      return StringUtils.shrinkStr(str);
    },
    toLocalString(str: string) {
      return StringUtils.toLocalString(str);
    },
    toStatusString(statusCode: number) {
      if (statusCode == 1) {
        return this.$t("job_queuing.column_sattus_executing");
      } else if (statusCode == 99) {
        return this.$t("job_queuing.column_sattus_done");
      }
      return '';
    },
    toControlString(controlCode: number) {
      
      if (controlCode !== undefined) {
        switch (controlCode) {
          case 0:
          case 1:
            return this.$t("job_queuing.column_control_start");
          case 2:
            return this.$t("job_queuing.column_control_hold");
          case 99:
            return this.$t("job_queuing.column_control_acquired");
        }
      }
      return '';
    },
    showCancelJobMessage(job: Job) {
      this.showCancelJobMessageBox = true;
      this.targetJob = job;
    },
    showColumnSettingDialog() {
      (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]).forEach((el) => {
        const item = this.headers.find(elm => elm.value == el.id);
        if (item) {
          el.label = item.text;
        }
      });

      this.columnSettingDialogVisible = true;
    },
    closeColumnSettingDialog(columns: string[]) {
      if (columns) {
        (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]) = this.headers.map((el) => {
          return { id: el.value, label: el.text, select: !!columns.find(elem => elem == el.value), disabled: el.disabled}
        });

        const visibleColumns = (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]).filter(elm => elm.select).map(el => el.id);
        localStorage.setItem('csj-job-queue-columns', JSON.stringify(visibleColumns));
      }
      this.columnSettingDialogVisible = false;
    },
    onClearFilter() {
      (this.setting as JobUISetting).locationFilterValue = '';
      (this.setting as JobUISetting).deviceIdFilterValue = '';
      (this.setting as JobUISetting).filterIndex = 0;
    },
    onCopy(str: string) {
      this.$copyText(str).then(() => {
        (this.copyItem as string | null) = str;
      });
    },
    fetchJobs(delay: number) {
      if (this.fetchId) {
        window.clearTimeout(this.fetchId);
        this.fetchId = 0;
      }

      this.fetchId = window.setTimeout(() => {
        const param = {
          second: -1,
          statusFilterValue: this.setting.filterIndex,
          locationFilterValue: this.setting.locationFilterValue,
          deviceIdFilterValue: this.setting.deviceIdFilterValue,
          ignorePushCommand: true,
          options: this.options
        };

        this.loading = true;
        this.$store.dispatch("user/fetchJobs", param).then((resp) => {
          this.jobs = resp.jobs;
          this.totalJobs = resp.count;
          this.loading = false;
        }).catch((err) => {
            this.loading = false;
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchId = 0;
      }, delay);
    }
  },
  created() {
    let visibleColumns: string[] = [];
    const data = localStorage.getItem('csj-job-queue-columns');
    if (data) {
      const columns = JSON.parse(data);
      if (columns && columns.length) {
        visibleColumns = columns;
      }
    }

    if (visibleColumns.length == 0) {
      visibleColumns = this.headers.filter(elm => !elm.isOption).map(el => el.value);
    }

    (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]) = this.headers.map((el) => {
      let isVisible = visibleColumns.findIndex(elm => elm == el.value) != -1;
      return { id: el.value, label: el.text, select: isVisible, disabled: el.disabled }
    });

    const uiSetting = this.$store.getters["user/uiSetting"]('JobQueue');
    if (uiSetting) {
      this.setting = uiSetting;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("user/saveUISetting", { key: 'JobQueue', setting: this.setting});
  }
})
