



















































































































































































































































































































































































































import Vue from 'vue'
import '@/assets/csj-v1.0/style.css'
import PrinterSendFileDialog from '../../components/user/PrinterSendFileDialog.vue'
import PrinterFWUpdateDialog from '../../components/user/PrinterFWUpdateDialog.vue'
import FileRegisterDialog from '../../components/user/FileRegisterDialog.vue'
import JobSettingDialog from '../../components/user/JobSettingDialog.vue'
import Breadcrumbs from '../../components/common/Breadcrumbs.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import { StringUtils } from '../../utils/StringUtils'
import { Device, FileInfo, DeviceFWUpdate, Job, DeviceUISetting } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'

export default Vue.extend({
  name: 'Devices',
  data: () => ({
    loading: false,
    printerSendFileDialogVisible: false,
    printerFWUpdateDialogVisible: false,
    fileRegisterDialogVisible: false,
    jobSettingDialogVisible: false,
    jobSettingDialogCallback: {},
    showErrorAlert: false,
    errorMessage: '',
    targetPrinters: [],
    atOption: '',
    options: {},
    devices: [],
    totalDevices: 0,
    fetchId: 0,
    selectedPrinter: [],
    files: [],
    lastDataFetchTime: 0,
    timerId: 0,

    setting: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'location',
      sortDesc: false,
      filterPanel: null,
      locationFilterValue: '',
      modelNameFilterValue: '',
      firmwareVersionFilterValue: '',
      serialNumberFilterValue: '',
      deviceIdFilterValue: '', 
      deviceStatusFilterValue: 0
    },
  }),
  components: {
    PrinterSendFileDialog,
    PrinterFWUpdateDialog,
    FileRegisterDialog,
    JobSettingDialog,
    MessageBox,
    Breadcrumbs
  },
  computed: {
    headers: function (): { text: string, value: string, width?: string, filter?: (arg: string | number) => boolean, align?: string, sortable?: boolean, cellClass?: string }[] {
      return [
        { text: this.$t("printer_management.column_place") as string, width: "180", value: 'location', cellClass:'cell-location' },
        { text: this.$t("printer_management.column_serialno") as string, width: "140", value: 'serialNumber' },
        { text: this.$t("printer_management.column_deviceid") as string, width: "100", value: 'deviceId' },
        { text: this.$t("printer_management.column_modelname") as string, width: "110", value: 'modelName' },
        { text: this.$t("printer_management.column_fwver") as string, width: "200", value: 'firmwareId' },
        { text: this.$t("printer_management.column_devstatus") as string, width: "120", value: 'stateCode', align: 'center' },
        { text: this.$t("printer_management.column_detail") as string, width: "60", value: 'detail', align: 'center', sortable: false  },
      ];
    },
    menuItems() {
      return [
        { label: this.$t("printer_management.menuitem_fwupdate"), icon: "mdi-printer" },
        { label: this.$t("printer_management.menuitem_msw_backup"), icon: "icon-msw_backup_icon" },
        { label: this.$t("printer_management.menuitem_msw_restore"), icon: "icon-msw_restore_icon" },
        { label: this.$t("printer_management.menuitem_file_regist"), icon: "icon-file_register_icon" },
        { label: this.$t("printer_management.menuitem_send_file"), icon: "icon-file_send_icon" },
      ];
    },
    statusCodeMenuText() {
      return [
        this.$t("printer_management.list_all"),
        this.$t("printer_management.list_normal"),
        this.$t("printer_management.list_error"),
        this.$t("printer_management.list_unknown")
      ];
    },
    breadcrumbs() {
      return [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Dashboard',
          }
        },
        {
          text: this.$t("printer_management.breadcrumbs"),
          disabled: false
        }
      ];
    },
  },
  watch: {
    options: {
      handler () {
        this.fetchDevices(0);
      },
      deep: true,
    },
    'options.page': {
      handler () {
        this.selectedPrinter = [];
      }
    },
    'options.itemsPerPage': {
      handler () {
        this.selectedPrinter = [];
      }
    },
    'setting.locationFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchDevices(500);
      }
    },
    'setting.deviceIdFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchDevices(500);
      }
    },
    'setting.modelNameFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchDevices(500);
      }
    },
    'setting.firmwareVersionFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchDevices(500);
      }
    },
    'setting.serialNumberFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchDevices(500);
      }
    },
    'setting.deviceStatusFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchDevices(0);
      }
    }
  },
  methods: {
    itemRowBackground(device: Device) {
      return this.getSeriousErrorMessage(device) != "" ? 'style-err': '';
    },

    getPagenationText(props: { pageStart: string, pageStop: string, itemsLength: string }): string {
      return StringUtils.format(this.$t("printer_management.table_pagenation") as string, props.pageStart, props.pageStop, props.itemsLength);
    },
    getStatusString(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      
      if (status == 3) {
        return this.$t("printer_management.list_unknown");
      } else if (status == 1) {
        return this.$t("printer_management.list_normal");
      }
      return this.$t("printer_management.list_error");
    },
    getErrorMessage(device: Device) {
      if (AppUtils.getStatus(device.stateCode, device.logDateTime) == 3) {
        return "";
      }
      return AppUtils.getErrorMessage(device);
    },
    getSeriousErrorMessage(device: Device) {
      if (AppUtils.getStatus(device.stateCode, device.logDateTime) == 3) {
        return "";
      }
      return AppUtils.getErrorMessage(device, 0x08);
    },
    getStateIcon(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      if (status == 3) {
        return 'icon-status-question_icon';
      } else if (status == 1) {
        return 'icon-status-ok_icon';
      }
      return 'icon-status-ng_icon';
    },
    getStateIconColor(state: number, dateTime: string) {
      const status = AppUtils.getStatus(state, dateTime);
      if (status == 3) {
        return 'blue-grey darken-2';
      } else if (status == 1) {
        return 'blue darken-2';
      }
      return 'red darken-2';
    },
    // ----- MSWページへ遷移 -----
    onPageDeviceMSW(printer: Device) {
      this.$router.push({ name: 'DeviceMSW', params: { id: printer.deviceId } });
    },

    // ----- ファイル送信ダイアログ -----
    showPrinterSendFileDialog() {
        this.$store.dispatch("user/fetchFiles").then((resp) => {
          this.printerSendFileDialogVisible = true;
          this.files = resp.files;
        }).catch((err) => {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
        });
    },
    closePrinterSendFileDialog(param: { info: FileInfo, atOption: string}) {
      if (param) {
        if (this.selectedPrinter.length) {
          for (let item of (this.selectedPrinter as Device[])) {
            const job: Job = {
              deviceId: item.deviceId,
              command: 'SEND',
              parameter: param.info.fileId,
              atOption: param.atOption,
              retry: 0
            }

            const msg = {
              message: "SEND",
              data: ["デバイスID", item.deviceId]
            };
            this.$store.dispatch("user/createJob", job).then(() => {
              this.$toasted.global.job_create_success(msg);
            }).catch((err) => {
              AppUtils.redirectIfSessionError(this.$router, err as Error);
              this.$toasted.global.job_create_error(msg);
            });
          }
        } else {
          this.errorMessage = this.$t('messages.no_printer_selected') as string;
          this.showErrorAlert = true;
        }
      }
      this.printerSendFileDialogVisible = false;
    },

    // ----- ファームウェアアップデートダイアログ -----
    showPrinterFirmwareUpdateDialog() {
      let modelNames = (this.selectedPrinter as Device[]).map((device) => {
        return device.modelName;
      });
      modelNames = Array.from(new Set(modelNames));
      this.$store.dispatch("user/fetchSupportedFirmwares", { modelNames: modelNames }).then((resp) => {
        const supportedFirmwares = resp.supportedFirmwares;
        (this.targetPrinters as DeviceFWUpdate[]) = (this.selectedPrinter as Device[]).map((device) => {
          const firmwares = supportedFirmwares ? supportedFirmwares[device.modelName]: [];
          let deviceForFWUpdate: DeviceFWUpdate = Object.assign({
              deviceId: device.deviceId,
              firmwares: firmwares ? firmwares: [],
              selectFirmware: firmwares && firmwares.length ? firmwares.length - 1: 0
          }, device);
          return deviceForFWUpdate;
        });
        this.printerFWUpdateDialogVisible = true;
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
      });
    },
    closePrinterFWUpdateDialog(fwUpdate: { devices: DeviceFWUpdate[], atOption: string}) {
      if (fwUpdate && fwUpdate.devices && fwUpdate.devices.length) {
        for (let item of fwUpdate.devices) {
          if (item.firmwares.length) {
            const fw = item.firmwares[item.selectFirmware];
            const job: Job = {
              deviceId: item.deviceId,
              command: 'UPDATE',
              parameter: fw.firmwareId,
              atOption: fwUpdate.atOption,
              retry: 0
            }
            const msg = {
              message: "UPDATE",
              data: ["デバイスID", item.deviceId]
            };
            this.$store.dispatch("user/createJob", job).then(() => {
              this.$toasted.global.job_create_success(msg);
            }).catch((err) => {
              AppUtils.redirectIfSessionError(this.$router, err as Error);
              this.$toasted.global.job_create_error(msg);
            });
          }
        }
      }
      this.printerFWUpdateDialogVisible = false;
      this.targetPrinters = [];
    },
    // ----- ファイル登録ダイアログ -----
    showFileRegisterDialog() {
      this.fileRegisterDialogVisible = true;
    },
    closeFileRegisterDialog() {
      this.fileRegisterDialogVisible = false;
    },
    showJobSettingDialog(callback: () => void) {
      this.jobSettingDialogVisible = true;
      if (this.jobSettingDialogCallback) {
        (this.jobSettingDialogCallback as () => void) = callback;
      }
    },
    closeJobSettingDialog(param: string) {
      this.atOption = param;
      if (param !== null) {
        (this.jobSettingDialogCallback as () => void)();
      }
      this.jobSettingDialogVisible = false;
    },
    onBackup() {
      this.showJobSettingDialog(() => {
        for (let item of (this.selectedPrinter as Device[])) {
          const job: Job = {
            deviceId: item.deviceId,
            command: 'BACKUP',
            parameter: '',
            atOption: this.atOption,
            retry: 0
          }

          const msg = {
            message: "BACKUP",
            data: ["デバイスID", item.deviceId]
          };
          this.$store.dispatch("user/createJob", job).then(() => {
            this.$toasted.global.job_create_success(msg);
          }).catch((err) => {
            AppUtils.redirectIfSessionError(this.$router, err as Error);
            this.$toasted.global.job_create_error(msg);
          });
        }
      });
    },
    onRestore() {
      this.showJobSettingDialog(() => {
        for (let item of (this.selectedPrinter as Device[])) {
          const job: Job = {
            deviceId: item.deviceId,
            command: 'RESTORE',
            parameter: '',
            atOption: this.atOption,
            retry: 0
          }

          const msg = {
            message: "RESTORE",
            data: ["デバイスID", item.deviceId]
          };
          this.$store.dispatch("user/createJob", job).then(() => {
            this.$toasted.global.job_create_success(msg);
          }).catch((err) => {
            AppUtils.redirectIfSessionError(this.$router, err as Error);
            this.$toasted.global.job_create_error(msg);
          });
        }
      });
    },
    onMenuSelect(itemIndex: number) {
      setTimeout(() => {
        switch (itemIndex) {
          case 0:
            this.showPrinterFirmwareUpdateDialog();
            break;
          case 1:
            this.onBackup();
            break;
          case 2:
            this.onRestore();
            break;
          case 3:
            this.showFileRegisterDialog();
            break;
          case 4:
            this.showPrinterSendFileDialog();
            break;
        }
      }, 50);
    },
    isMenuItemDisabled(itemIndex: number) {
        switch (itemIndex) {
          case 0:
          case 1:
          case 2:
            return !this.selectedPrinter.length;
          case 3:
          case 4:
            return false;
          
          default:
            return true;
        }
    },
    onClearFilter() {
      (this.setting as DeviceUISetting).locationFilterValue = '';
      (this.setting as DeviceUISetting).modelNameFilterValue = '';
      (this.setting as DeviceUISetting).firmwareVersionFilterValue = '';
      (this.setting as DeviceUISetting).serialNumberFilterValue = '';
      (this.setting as DeviceUISetting).deviceIdFilterValue = '';
      (this.setting as DeviceUISetting).deviceStatusFilterValue = 0;
    },
    fetchDevices(delay: number, disableLoading?: boolean) {
      if (this.fetchId) {
        window.clearTimeout(this.fetchId);
        this.fetchId = 0;
      }

      this.fetchId = window.setTimeout(() => {
        const param = {
          locationFilterValue: this.setting.locationFilterValue,
          modelNameFilterValue: this.setting.modelNameFilterValue,
          firmwareVersionFilterValue: this.setting.firmwareVersionFilterValue,
          serialNumberFilterValue: this.setting.serialNumberFilterValue,
          deviceIdFilterValue: this.setting.deviceIdFilterValue, 
          deviceStatusFilterValue: this.setting.deviceStatusFilterValue,
          options: this.options
        };

        if (!disableLoading) {
          this.loading = true;
        }
        this.$store.dispatch("user/fetchDevices", param).then((resp) => {
          this.devices = resp.devices;
          this.totalDevices = resp.count;
          this.loading = false;
        }).catch((err) => {
            this.loading = false;
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchId = 0;
        this.lastDataFetchTime = (new Date).getTime();
      }, delay);
    }
  },
  created() {
    const uiSetting = this.$store.getters["user/uiSetting"]('Devices');
    if (uiSetting) {
      this.setting = uiSetting;
    }
  },
  mounted() {
    this.timerId = setInterval(() => {
      // 前回デバイス情報取得から10秒経過してるなら、再度デバイス情報取得を行う
      if ((new Date).getTime() - this.lastDataFetchTime > 1000 * 10) {
        this.fetchDevices(0, true);
      }
    }, 1000) as unknown as number;
  },
  beforeDestroy() {
    this.$store.dispatch("user/saveUISetting", { key: 'Devices', setting: this.setting});
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = 0;
    }
  }
})
