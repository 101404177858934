















































































































































import Vue, { PropType } from 'vue'
import { Notification } from '../../dataTypes'
import MessageBox from '../../components/common/MessageBox.vue'

export default Vue.extend({
  name: 'NotificationDialog',

  data: () => ({
    selected: "",
    supressDisplay: false,
    showErrorAlert: false,
    targetNotification: { notificationId: "", body: "", title: "", strtDate: undefined, markDate: undefined }
  }),
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal) {
          if (!this.startupNotificationId) {
            this.targetNotification = { notificationId: "", body: "", title: "", strtDate: undefined, markDate: undefined };
          }
          this.selected = "";
        }
      }
    },
    startupNotificationId: {
      handler: async function(newVal, oldVal) {
        if (!oldVal && newVal) {
          await this.onNotificationItem(this.startupNotificationId);
          this.$emit('notification-setup-done');
        }
      }
    }
  },
  methods: {
    localDateString(dt: Date | string | undefined): string {
      if (!dt) {
        return '';
      }
      let str = dt.toLocaleString();
      const index = str.lastIndexOf(":");
      str = str.substring(0, index);
      return str;
    },
    async onNotificationItem(val: string | undefined) {
      try {
        let notif = await this.$store.dispatch("user/fetchNotification", { notificationId: val });
        this.targetNotification = notif;

        const param = {
          notificationId: val,
          setMark: true
        }
        this.$store.dispatch("user/setNotificationMark", param);
      } catch (err) {
        this.showErrorAlert = true;
      }
    },
    onBack() {
      this.selected = "";
      this.targetNotification = { notificationId: "", body: "", title: "", strtDate: undefined, markDate: undefined };
    },
    onClose() {
      if (this.startupNotificationId && this.supressDisplay) {
        const param = {
          notificationId: this.startupNotificationId,
          setSupressDisp: true
        }
        this.$store.dispatch("user/setNotificationMark", param);
      }
      this.$emit('close-request', false);
    }
  },
  computed: {
    supressDisplayLabel() {
      return this.$t("notification_dlg.check_supress_display");        
    }
  },
  components: {
    MessageBox
  },
  props: {
    isVisible: Boolean,
    notifications: Array as PropType<Notification[]>,
    startupNotificationId: String
  }
})
