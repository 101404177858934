




































































































































































































































































































































































































import Vue from 'vue';
import '@/assets/csj-v1.0/style.css'
// import * as appConfig from '../../appConfig'
import PasswordChangeDialog from '../../components/user/PasswordChangeDialog.vue'
import LicenseAgreementDialog from '../../components/user/LicenseAgreementDialog.vue'
import PrivacyPolicyDialog from '../../components/user/PrivacyPolicyDialog.vue'
import NotificationDialog from '../../components/user/NotificationDialog.vue'
import VersionDialog from '../../components/user/VersionDialog.vue'
import { LangDesc } from '../../dataTypes'
import { StringUtils } from '../../utils/StringUtils'
import { AppUtils } from '../../utils/AppUtils'
import { Notification } from '../../dataTypes'
import MessageBox from '../../components/common/MessageBox.vue'

export default Vue.extend({
  name: 'App',

  data: () => ({
    drawer: true,
    loading: true,
    passwordChangeDialogVisible: false,
    licenseAgreementDialogVisible: false,
    privacyPolicyDialogVisible: false,
    notificationDialogVisible: false,
    versionDialogVisible: false,
    licenseText: "",
    privacyPolicyText: "",
    startupNotificationId: "",
    showErrorAlert: false,
    jobCount: 0,
    errorJobCount: 0,
    langItems: [
      { text: '日本語', value: 'ja' },
      { text: 'English', value: 'en' },
    ]
  }),
  computed: {
    notifications() {
      const language = this.$i18n.locale == 'en' ? 1: 0;        
      return this.$store.getters['user/notifications'].filter((el: Notification) => el.language == language);
    },
    currentLanguage() {
      const lang = (this.langItems as LangDesc[]).find(el => el.value == this.$i18n.locale);
      return lang ? lang.text: 'Unknown';
    },
    currentAccount() {
      return this.$store.getters["user/customerName"];
    },
    isProxy() {
      return this.$store.getters['user/isProxy'];
    },
    proxyName() {
      return this.$store.getters['user/proxyName'];
    },
    unreadNotificationCount() {
      const notifications = this.notifications as Notification[];
      if (notifications) {
        return notifications.filter((el: Notification) => !el.markDate).length;
      }
      return 0;
    }
  },
  components: {
    PasswordChangeDialog,
    LicenseAgreementDialog,
    PrivacyPolicyDialog,
    NotificationDialog,
    VersionDialog,
    MessageBox
  },
  methods: {
    logout() {
      const isProxy = this.isProxy;
      const baseSiteUrl = "";
      this.$store.dispatch("user/logout");
      if (isProxy) {
        this.$router.replace({path: baseSiteUrl + 'admin/customers'});
        window.location.reload();
      } else {
        this.$router.replace({path: baseSiteUrl + '/login'});
        window.location.reload();
      }
    },
    onChangeLanguage(item: LangDesc) {
      this.$i18n.locale = item.value;
      localStorage.setItem('csj-locale', item.value);
    },
    onChangePassword() {
      this.passwordChangeDialogVisible = true;
    },
    async onLicenseAgreement() {
      try {
        const lisenceAgreement = await this.$store.dispatch("user/fetchLicenseAgreement", this.$i18n.locale == 'en' ? 1: 0);
        this.licenseText = (lisenceAgreement && lisenceAgreement.body) ? lisenceAgreement.body: '';
        this.licenseAgreementDialogVisible = true;
      } catch (err) {
        this.showErrorAlert = true;
      }
    },
    async onPrivacyPolicy() {
      try {
        const privacyPolicy = await this.$store.dispatch("user/fetchPrivacyPolicy", this.$i18n.locale == 'en' ? 1: 0);
        this.privacyPolicyText = (privacyPolicy && privacyPolicy.body) ? privacyPolicy.body: '';
        this.privacyPolicyDialogVisible = true;
      } catch (err) {
        this.showErrorAlert = true;
      }
    },
    onNotification() {
      this.startupNotificationId = "";
      this.notificationDialogVisible = true;
    },
    onVersion() {
      this.versionDialogVisible = true;
    },
    async getJobStatus() {
      if (this.loading == false) {
        try {
          const resp = await this.$store.dispatch("user/getJobCount");
          this.jobCount = resp.jobCount;
          this.errorJobCount = resp.errorJobCount;
        } catch (err) {
          AppUtils.redirectIfSessionError(this.$router, err as Error);
        }
      }
    }
  },
  async created() {
    const baseSiteUrl = "";

    const locale = localStorage.getItem('csj-locale');
    if (locale) {
      this.$i18n.locale = locale;
    }

    let lang;
    switch (this.$i18n.locale) {
      case 'ja':
        lang = 'jp';
        break;
      default:
        lang = 'us';
    }
    document.getElementsByTagName('html')[0].lang = lang;

    if (!this.$router.currentRoute.meta || !this.$router.currentRoute.meta.error) {
      await this.$store.dispatch("user/startApp");

      this.$store.dispatch("user/fetchCustomerName").then(() => {
        if (!this.$store.getters["user/customerName"]) {
          AppUtils.redirectIfSessionError(this.$router, Error("-1"));
        }
        
        (async () => {
          if (this.$store.getters["user/isProxy"]) {
            await this.$store.dispatch("user/startApp");
            this.loading = false;
            this.getJobStatus();
            
            await this.$store.dispatch("user/fetchNotifications");
          } else {
            this.loading = false;
            this.getJobStatus();

            try {
              await this.$store.dispatch("user/fetchNotifications");
              const notifications = this.notifications as Notification[];
              if (notifications) {
                for (let i = 0; i < notifications.length; i++) {
                  const notification = notifications[i];
                  if (notification.suppressDispDate) {
                    // ログイン時に表示しないが設定されていた
                    break;
                  }

                  // 公開終了日が未来のものを表示する
                  if ((notification.endDate as Date).getTime() > (new Date).getTime()) {
                    this.startupNotificationId = notifications[i].notificationId as string;
                  }
                }
              }
            } catch (err) {
              console.log(err);
            }
          }
        })();
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err);
        if (parseInt((err as Error).message) == -3) {
          this.$router.replace({path: baseSiteUrl + '/login'});
          window.location.reload();
        }
      });
/*
      this.$store.dispatch("user/fetchNotifications").then(() => {
        const notifications = this.notifications as Notification[];
        if (notifications) {
          for (let i = 0; i < notifications.length; i++) {
            const notification = notifications[i];
            if (notification.suppressDispDate) {
              // ログイン時に表示しないが設定されていた
              break;
            }

            // 公開終了日が未来のものを表示する
            if ((notification.endDate as Date).getTime() > (new Date).getTime()) {
              this.startupNotificationId = notifications[i].notificationId as string;
            }
          }
        }
      }).catch((err) => {
        console.log(err);
      });
*/
    } else {
      this.loading = false;
    }

    this.$toasted.register('job_create_success', (payload) => {
      return StringUtils.format(this.$t("messages.toast_job_created") as string, payload.data[1], payload.message);
    }, {
      className: "toast-success",
      duration: 6000,
      action: {
        text: this.$t("common_alert.button_close") as string,
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        }
      }
    });

    this.$toasted.register('job_create_error', (payload) => {
      return StringUtils.format(this.$t("messages.toast_job_failed") as string, payload.data[1], payload.message);
    }, {
      className: "toast-error",
      duration: 6000,
      action: {
        text: this.$t("common_alert.button_close") as string,
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        }
      }
    });
  },
  mounted() {
    if (!this.$router.currentRoute.meta || !this.$router.currentRoute.meta.error) {
      setInterval(this.getJobStatus, 10 * 1000);    // 10秒
    }
  },
  beforeDestroy() {
    this.loading = false;
  }
});
