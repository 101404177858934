














































































import Vue from 'vue'

export default Vue.extend({
  name: 'PrivacyPolicyDialog',

  data: () => ({
  }),
  methods: {
  },
  computed: {
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal) {
          setTimeout(() => {
            if (this.$refs.textArea) {
              (this.$refs.textArea as Element).scrollTop = 0;
            }
          }, 0);            
        }
      }
    }
  },
  props: {
    isVisible: Boolean,
    message: String,
  }
})
