






















































































































































import Vue from 'vue'
import MessageBox from '../../components/common/MessageBox.vue'
import { StringUtils } from '../../utils/StringUtils'
import { AppUtils } from '../../utils/AppUtils'

export default Vue.extend({
  name: 'PasswordChangeDialog',

  data: () => ({
    valid: false,
    password: '',
    newPassword: '',
    confirmationPassword: '',
    showErrorAlert: false,
    errorMessage: '',
    request: false
  }),
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal && this.$refs.form) {
          (this.$refs.form as HTMLFormElement).reset();
        }
      }
    }
  },
  methods: {
    onApply() {
      if (this.newPassword != this.confirmationPassword) {
        this.errorMessage = this.$t('messages.password_mismatch_message') as string;
        this.showErrorAlert = true;
        return;
      }

      this.request = true;
      this.$store.dispatch("user/changePassword", { password: this.password, newPassword: this.newPassword }).then(() => {
        this.$emit('close-request', null);
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
        this.errorMessage = this.$t('messages.failed_chnaged_password_message') as string;
        this.showErrorAlert = true;
      }).finally(() => {
        this.request = false;
      });
    }
  },
  computed: {
    passwordRules(): ((v: string) => boolean | string)[] {
      return [
        (v: string) => !!v || this.$t('messages.customerpassword_validation') as string,
        (v: string) => !v || !!v.match(/^[A-Za-z0-9]*$/) || this.$t('messages.input_valid_string_number') as string,
        (v: string) => !v || (v.length >= 8 && v.length <= 20) || StringUtils.format(this.$t("messages.invalid_string_range_validation") as string, '8', '20'),
      ];
    },
  },
  components: {
    MessageBox,
  },
  props: {
    isVisible: Boolean,
  }
})
