




























































































































































































































































































































































































































import Vue from 'vue'
import '@/assets/csj-v1.0/style.css'
import ColumnSettingDialog from '../../components/common/ColumnSettingDialog.vue'
import MessageBox from '../../components/common/MessageBox.vue'
import Breadcrumbs from '../../components/common/Breadcrumbs.vue'
import { StringUtils } from '../../utils/StringUtils'
import { Job, JobUISetting } from '../../dataTypes'
import { AppUtils } from '../../utils/AppUtils'

export default Vue.extend({
  name: 'JobHistory',

  data: () => ({
    loading: false,
    showErrorAlert: false,
    mouseOverItem: null,
    copyItem: null,
    columnSettingDialogVisible: false,
    columnIdList: [],
    options: {},
    jobs: [],
    totalJobs: 0,
    fetchId: 0,

    setting: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'lastUpdate',
      sortDesc: true,
      filterIndex: 0,
      filterPanel: null,
      locationFilterValue: '',
      deviceIdFilterValue: '',
    },
  }),
  components: {
    ColumnSettingDialog,
    MessageBox,
    Breadcrumbs
  },
  computed: {
    headers: function (): { text: string, value: string, width?: string, filter?: (arg: string) => boolean, align?: string, sortable?: boolean, disabled?: boolean, isOption?: boolean }[] {
      return [
        { text: this.$t("job_history.column_place") as string, width: "140", value: 'location' },
        { text: this.$t("job_history.column_jobid") as string, width: "100", value: 'jobId', isOption: true  },
        { text: this.$t("job_history.column_deviceid") as string, width: "140", value: 'deviceId', disabled: true   },
        { text: this.$t("job_history.column_command") as string, width: "100", value: 'command', disabled: true  },
        { text: this.$t("job_history.column_parameter") as string, width: "120", value: 'parameter', isOption: true  },
        { text: this.$t("job_history.column_sattus") as string, width: "110", value: 'status', align: 'center'  },
        { text: this.$t("job_history.column_atoption") as string, width: "140", value: 'atOption'  },
        { text: this.$t("job_history.column_agentjobid") as string, width: "170", value: 'agentJobId', isOption: true  },
        { text: this.$t("job_history.column_control") as string, width: "130", value: 'control', align: 'center'  },
        { text: this.$t("job_history.column_result") as string, width: "100", value: 'result', disabled: true  },
        { text: this.$t("job_history.column_gatewayid") as string, width: "140", value: 'ioGatewayId', isOption: true  },
        { text: this.$t("job_history.column_update_date") as string, width: "170", value: 'lastUpdate', disabled: true  },
        { text: this.$t("job_history.column_confirm_date") as string, width: "170", value: 'confirmedDateTime', disabled: true  },
        { text: this.$t("job_history.column_verification") as string, width: "60", value: 'detail', align: 'center', sortable: false, disabled: true  },
      ];
    },
    customHeaders: function (): { text: string, value: string, filter?: (arg: string) => boolean, align?: string, sortable?: boolean }[] {
      return this.headers.filter(elem => {
        const col = (this.columnIdList as { id: string, label: string, select: boolean }[]).find((el) => el.id == elem.value);
        return col && col.select;
      });
    },
    filterItems() {
      return [
        this.$t("job_history.filter_all"),
        this.$t("job_history.filter_confirmed"),
        this.$t("job_history.filter_unconfirmed"),
      ]
    },
    breadcrumbs() {
      return [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Dashboard',
          }
        },
        {
          text: this.$t("job_history.breadcrumbs"),
          disabled: false
        }
      ]
    },
    rowItemTextClass(): (item: Job, color?: string) => string {
      return (item: Job, color?: string): string => {
        let str = (item && !item.confirmedDateTime) ? 'font-weight-bold': '';
        if (color) {
          str += ` ${color}--text`;
        }
        return str;
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchJobs(0);
      },
      deep: true,
    },
    'setting.locationFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchJobs(500);
      }
    },
    'setting.deviceIdFilterValue': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchJobs(500);
      }
    },
    'setting.filterIndex': {
      handler () {
        (this.options as { page: number }).page = 1;
        this.fetchJobs(0);
      }
    }
  },
  methods: {
    getPagenationText(props: { pageStart: string, pageStop: string, itemsLength: string }): string {
      return StringUtils.format(this.$t("job_history.table_pagenation") as string, props.pageStart, props.pageStop, props.itemsLength);
    },
    doConfirm(item: Job) {
      this.$store.dispatch("user/confirmJobs", item).then((resp) => {
        const job: Job = resp[0];
        for (let i = 0; i < this.jobs.length; i++) {
          if ((this.jobs as Job[])[i].jobId === job.jobId) {
            Vue.set(this.jobs, i, job);
            break;
          }
        }
      }).catch((err) => {
        AppUtils.redirectIfSessionError(this.$router, err as Error);
        this.showErrorAlert = true;
      });
    },
    shrinkStr(str?: string) {
      return StringUtils.shrinkStr(str);
    },
    toLocalString(str: string) {
      return StringUtils.toLocalString(str);
    },
    toStatusString(statusCode: number) {
      return statusCode === undefined ? '': statusCode == 0 ? this.$t("job_history.column_sattus_executing"): this.$t("job_history.column_sattus_done");
    },
    toControlString(controlCode: number) {
      
      if (controlCode !== undefined) {
        switch (controlCode) {
          case 0:
          case 1:
            return this.$t("job_history.column_control_hold");
          case 2:
            return this.$t("job_history.column_control_start");
          case 99:
            return this.$t("job_history.column_control_acquired");
        }
      }
      return '';
    },
    toResultString(resultCode: number) {
      return resultCode == 0 ? this.$t("job_history.column_result_success"): this.$t("job_history.column_result_error");
    },
    showColumnSettingDialog() {
      (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]).forEach((el) => {
        const item = this.headers.find(elm => elm.value == el.id);
        if (item) {
          el.label = item.text;
        }
      });

      this.columnSettingDialogVisible = true;
    },
    closeColumnSettingDialog(columns: string[]) {
      if (columns) {
        (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]) = this.headers.map((el) => {
          return { id: el.value, label: el.text, select: !!columns.find(elem => elem == el.value), disabled: el.disabled}
        });

        const visibleColumns = (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]).filter(elm => elm.select).map(el => el.id);
        localStorage.setItem('csj-job-history-columns', JSON.stringify(visibleColumns));
      }
      this.columnSettingDialogVisible = false;
    },
    onClearFilter() {
      (this.setting as JobUISetting).locationFilterValue = '';
      (this.setting as JobUISetting).deviceIdFilterValue = '';
      (this.setting as JobUISetting).filterIndex = 0;
    },
    onCopy(str: string) {
      this.$copyText(str).then(() => {
        (this.copyItem as string | null) = str;
      });
    },
    fetchJobs(delay: number) {
      if (this.fetchId) {
        clearTimeout(this.fetchId);
        this.fetchId = 0;
      }

      this.fetchId = setTimeout(() => {
        const param = {
          second: -1,
          confirmedFilterValue: this.setting.filterIndex,
          locationFilterValue: this.setting.locationFilterValue,
          deviceIdFilterValue: this.setting.deviceIdFilterValue,
          ignorePushCommand: true,
          options: this.options
        };

        this.loading = true;
        this.$store.dispatch("user/fetchPastJobs", param).then((resp) => {
          this.jobs = resp.jobs;
          this.totalJobs = resp.count;
          this.loading = false;
        }).catch((err) => {
            this.loading = false;
            AppUtils.redirectIfSessionError(this.$router, err as Error);
        });

        this.fetchId = 0;
      }, delay) as unknown as number;
    }
  },
  created() {
    let visibleColumns: string[] = [];
    const data = localStorage.getItem('csj-job-history-columns');
    if (data) {
      const columns = JSON.parse(data);
      if (columns && columns.length) {
        visibleColumns = columns;
      }
    }

    if (visibleColumns.length == 0) {
      visibleColumns = this.headers.filter(elm => !elm.isOption).map(el => el.value);
    }

    (this.columnIdList as { id: string, label: string, select: boolean, disabled?: boolean }[]) = this.headers.map((el) => {
      let isVisible = visibleColumns.findIndex(elm => elm == el.value) != -1;
      return { id: el.value, label: el.text, select: isVisible, disabled: el.disabled }
    });

    const uiSetting = this.$store.getters["user/uiSetting"]('JobHistory');
    if (uiSetting) {
      this.setting = uiSetting;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("user/saveUISetting", { key: 'JobHistory', setting: this.setting});
  }
})
